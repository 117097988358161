import React from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

// CustomButton component
const CustomButton = ({ onClick, buttonText, style, type="button", disabled = false }) => {
    const theme = useTheme();

    // Define the base style for the button
    const baseStyle = {
        backgroundColor: theme.palette.main[400],
        textDecoration: 'none',
        color: 'white',
        padding: '10px 10px',
        fontSize: '1.10rem',
        borderRadius: '5%',
        width: '10rem',
        textTransform: 'none',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: theme.palette.main[600],
        },
        ...style
    };

    // Return the button
    return (
        <Button type={type} disabled={disabled} variant="contained" onClick={onClick} sx={baseStyle}>
            {buttonText}
        </Button>
    );
};

export default CustomButton;
