import React, {useState, useEffect, useRef} from 'react';
import { Box, LinearProgress, Typography, Select, MenuItem, FormControl, Paper, useTheme } from '@mui/material';

// Contexts
import { useSidebar } from 'utils/SidebarProvider'
import { useSelector } from "react-redux";

// Icons
import { CloudUpload, Home, ContactEmergency, Draw } from '@mui/icons-material';

const ProgressControl = ( ) => {
    // Theme and States
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const user = useSelector((state) => state.persistedReducer.user);

    // Navigation State
    const [activities, setActivities] = useState([
        { name: "Introduction", icon: <Home fontSize='large'/> , progress: 0 },
        { name: "Upload", icon: <CloudUpload fontSize='large'/>, progress: 0 },
        { name: "Metadata", icon: <ContactEmergency fontSize='large'/>, progress: 0 },
        { name: "Annotation", icon: <Draw fontSize='large'/>, progress: 0 },
    ]);

    // References
    const containerRef = useRef(null);
    const projectSelectionRef = useRef(null);
    const projectNavigationRef = useRef(null);

    // Context
    const { steps, activeStep, setActiveStep, projects, activeProject, setActiveProject, numberOfMetadata, numberOfImages, numberOfAnnotations } = useSidebar();

    const handleActivityClick = (activityIndex) => {
        setActiveStep(activityIndex);
    };

    const handleProjectChange = (event) => {
        setActiveProject(event.target.value);
    };

    // Control the progress
    const [imagesRequested, setImagesRequested] = useState(200);

    // Use effect to update the values when the active project changes
    useEffect(() => {
        // If projects is not empty
        if (projects.length > 0) {
            const projectObject = projects[activeProject];
            const projectName = Object.keys(projectObject)[0];
            const projectDetails = projectObject[projectName];

            // Update the number of images
            setImagesRequested(projectDetails.imagesRequired);
        }
    }, [activeProject, projects]);

    // Use effect to refresh the progress based on imagesRequested, numberOfMetadata, numberOfImages, numberOfAnnotations, steps
    useEffect(() => {
        // If projects is not empty
        if (projects.length > 0) {
            // Copy the activities in an object to make changes
            const updatedActivities = [...activities];

            // Check the progress of the step 0
            if (steps[0].completed) {
                updatedActivities[0].progress = 100;
            } else {
                updatedActivities[0].progress = 0;
            }

            // Check the progress of the step 1
            let progressStep1 = (numberOfImages/imagesRequested) * 100;
            if (progressStep1 > 100) {
                progressStep1 = 100;
            }
            updatedActivities[1].progress = parseFloat(progressStep1.toFixed(2));

            // Check the progress of the step 2
            let progressStep2 = (numberOfMetadata/imagesRequested) * 100;
            if (progressStep2 > 100) {
                progressStep2 = 100;
            }
            updatedActivities[2].progress = parseFloat(progressStep2.toFixed(2));

            // Check the progress of the step 3
            let progressStep3 = (numberOfAnnotations/imagesRequested) * 100;
            if (progressStep3 > 100) {
                progressStep3 = 100;
            }
            updatedActivities[3].progress = parseFloat(progressStep3.toFixed(2));

            // Update the activities
            setActivities(updatedActivities);
        }
    }, [projects, imagesRequested, numberOfMetadata, numberOfImages, numberOfAnnotations, steps]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Box ref={containerRef} display='flex' flexDirection='column' justifyContent='space-between' alignItems='center' width='100%' height='100%' bgcolor='white' paddingTop='1rem' gap='1.5rem'>

            {/* 1. Project Selection */}
            <Box ref={projectSelectionRef} sx={{ padding:theme.spacing(1), width:'100%'}}>
                <Paper elevation={3} sx={{ padding: '1rem', display: 'flex', flexDirection: 'column', width:'100%'}}>
                    <Typography variant="h5" component="h5" sx={{ color: theme.palette.main[500], fontWeight: 'bold', fontSize: '1.25rem', mb:'1rem'}}>
                        Selected Project
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            labelId="project-selector-label"
                            id="project-selector"
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            value={activeProject}
                            onChange={handleProjectChange}
                        >
                            {projects.map((project, index) => (
                                <MenuItem key={index} value={index}>
                                    {Object.keys(project)[0]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Paper>
            </Box>

            {/* 2. Project Navigation */}
            <Box ref={projectNavigationRef} sx={{ padding:theme.spacing(1), display: 'flex', flexDirection: 'column', width:'100%'}}>
                <Paper elevation={3} sx={{ paddingTop:'1rem', paddingBottom:'1rem'}} >

                    <Typography variant="h5" component="h5" sx={{ color: theme.palette.main[500], fontWeight: 'bold', fontSize: '1.25rem', paddingLeft:'1rem'}}>
                        Navigation
                    </Typography>

                    {activities.map((activity, index) => (
                        user.includes('Image') && index === 3 ? null :
                            <Box key={index}
                            sx={{ display: 'flex', alignItems: 'center', paddingBottom: '1.25rem', cursor: 'pointer',
                                '&:hover': {backgroundColor: theme.palette.grey[200]}, paddingTop: '1.25rem', paddingLeft: '1rem', paddingRight: '1rem',
                                bgcolor:index === activeStep ? theme.palette.grey[200] : 'transparent' }}
                            onClick={() => handleActivityClick(index)}
                            >
                            <Box sx={{ flex: '0 0 auto', color: theme.palette.main[500], marginRight: '2rem' }}>
                                {activity.icon}
                            </Box>

                            <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', marginRight: '2rem' }}>
                                <Typography variant="h5" color={theme.palette.main[500]} sx={{ marginBottom: '0.25rem' }}>
                                {activity.name}
                                </Typography>
                                <LinearProgress variant="determinate" value={activity.progress} sx={{ height: 10 }} />
                            </Box>

                            <Typography variant="h5" sx={{ flex: '0 0 auto', color: theme.palette.main[500] }}>
                                {activity.progress}%
                            </Typography>
                            </Box>
                        ))}

                </Paper>
            </Box>

            <Box sx={{ padding: theme.spacing(1), display: 'flex', flexDirection: 'column', width: '100%', flexGrow: 1 }}>

            </Box>
        </Box>
    );
}

export default ProgressControl;