// Color palette
const colorTokens = {
    grey: {
        0: "#ffffff",
        10: "#f5f5f5",
        50: "#f0f0f0",
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
        1000: "#000000",
    },

    main: {
        100: "#ccd6e6",
        200: "#99accd",
        300: "#6683b4",
        400: "#33599b",
        500: "#003082",
        600: "#002668",
        700: "#001d4e",
        800: "#001334",
        900: "#000a1a"
    },

    primaryLight: {
        100: "#f5fbfc",
        200: "#ebf7f8",
        300: "#e0f4f5",
        400: "#d6f0f1",
        500: "#ccecee",
        600: "#a3bdbe",
        700: "#7a8e8f",
        800: "#525e5f",
        900: "#292f30"
    },

    black: {
        100: "#d0d1d2",
        200: "#a2a2a5",
        300: "#737477",
        400: "#45454a",
        450: "#2c2e3a",
        500: "#16171d",
        600: "#121217",
        700: "#0d0e11",
        800: "#09090c",
        900: "#040506"
    },

    primary: {
        100: "#cedfe5",
        200: "#9dbecb",
        300: "#6b9eb2",
        400: "#3a7d98",
        500: "#095d7e",
        600: "#074a65",
        700: "#05384c",
        800: "#042532",
        900: "#021319"
    },

    secondaryLight: {
        100: "#f9fef7",
        200: "#f3feef",
        300: "#eefde6",
        400: "#e8fdde",
        500: "#e2fcd6",
        600: "#b5caab",
        700: "#889780",
        800: "#5a6556",
        900: "#2d322b"
    },

    secondary: {
        100: "#d0eae5",
        200: "#a1d5cc",
        300: "#72c0b2",
        400: "#43ab99",
        500: "#14967f",
        600: "#107866",
        700: "#0c5a4c",
        800: "#083c33",
        900: "#041e19"
    },

    failure: {
        100: "#f8d3d3",
        200: "#f1a7a7",
        300: "#ea7b7b",
        400: "#e34f4f",
        500: "#dc2323",
        600: "#b01c1c",
        700: "#841515",
        800: "#580e0e",
        900: "#2c0707",
    },

    success: {
        100: "#def1de",
        200: "#bee3be",
        300: "#9dd49d",
        400: "#7dc67d",
        500: "#5cb85c",
        600: "#4a934a",
        700: "#376e37",
        800: "#254a25",
        900: "#122512"
    },

};

// MUI theme settings
const themeSettings = (mode) => {
    return {
        palette: {
            // Palette values
            main: {
                ...colorTokens.main,
                main: colorTokens.main[500],
            },
            primary: {
                ...colorTokens.primary,
                main: colorTokens.primary[400],
                light: colorTokens.primaryLight[400],
            },
            secondary: {
                ...colorTokens.secondary,
                main: colorTokens.secondary[400],
            },
            neutral: {
                ...colorTokens.grey,
                main: colorTokens.grey[500],
            },
            background: {
                default: "#f5f5f5",
                alt: "#42a5f5",
            },
            failure: {
                ...colorTokens.failure,
                main: colorTokens.failure[500],
            },
            success: {
                ...colorTokens.success,
                main: colorTokens.success[500],
            },
            black: {
                ...colorTokens.black,
                main: colorTokens.black[500],
            },
        },

        typography: {
            fontFamily: ["Roboto", "sans-serif"].join(","),
            fontSize: 12,
            h1: {fontFamily: ["Roboto", "sans-serif"].join(","), fontSize: 40},
            h2: {fontFamily: ["Roboto", "sans-serif"].join(","), fontSize: 32},
            h3: {fontFamily: ["Roboto", "sans-serif"].join(","), fontSize: 24},
            h4: {fontFamily: ["Roboto", "sans-serif"].join(","), fontSize: 20},
            h5: {fontFamily: ["Roboto", "sans-serif"].join(","), fontSize: 16},
            h6: {fontFamily: ["Roboto", "sans-serif"].join(","), fontSize: 14},
        },
    };
};

//  Exporting the color palette and theme settings for MUI
export { themeSettings };