import '../../utils/Styles/background.css'
import React from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';

// Custom Components
import LoginForm  from './LoginForm';

// Logo
const logo = '/images/logo.svg';

const LoginPage = () => {
    // Check the theme and the screen size
    const theme = useTheme();

    // Render the component
    return(
        <Box className="gridBackground" display='flex' flexDirection='column' style={{ height: '100%' }}>

            {/* 1. Top bar */}
            <Box width="100%" height="70px" bgcolor={theme.palette.main[500]} p={theme.spacing(3)} display="flex" justifyContent="space-between" alignItems="center">

                {/* Logo */}
                <Box display="flex" alignItems="center" height="70px" maxWidth="100%" pl='2rem' pr='2rem' gap='1rem'>
                    <img src={logo} alt="Logo" style={{ width: '50px', height: '50px', color:'white'}} />
                    <Typography variant="h4" component="h4" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.5rem' }}>HS One Advanced AI Research Lab</Typography>
                </Box>

                <Typography variant="h5" component="h5" sx={{ color: 'white', fontSize: '1.25rem' }}>Annotation Tool</Typography>
            </Box>


            {/* 2. Content */}
            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                <Paper
                    elevation={20}
                    style={{ padding: '2rem', width: '80vw', maxWidth: '50rem', height: '30rem', maxHeight: '60rem', borderRadius: '1rem', display: 'flex',
                             flexDirection: 'column', alignItems: 'center', backgroundColor:'white',
                             boxShadow: `8px 8px 30px ${theme.palette.black[900]}`
                    }}
                >
                    {/* 2.1. Title */}
                    <Typography fontWeight="bold" variant="h1" mb={1} color={theme.palette.main[500]}>
                        Welcome to Anotia
                    </Typography>

                    {/* 2.2. Subtitle */}
                    <Typography variant="h5" mb={8} color={theme.palette.main[300]}>
                            Empowering your diagnostic abilities
                    </Typography>

                    {/* 2.3. Form */}
                    <LoginForm />

                </Paper>
            </Box>
        </Box>
    );
};

export default LoginPage;