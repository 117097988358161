import '../../../utils/Styles/background.css'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Box, Typography, Menu, MenuItem, Button, Toolbar, useTheme } from '@mui/material';

// State
import { setLogout } from "utils/State"

// Icons
import { ArrowDropDownOutlined } from '@mui/icons-material';

// Logo
const logo = '/images/logo.svg';

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
    // Dispatch and theme
    const dispatch = useDispatch();
    const theme = useTheme();

    // Get the user info from the state and build the full name
    const user = useSelector((state) => state.persistedReducer.user);

    // Dropdown for the user profile
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = (action) => {
        // Step 1. Close the dropdown
        setAnchorEl(null);

        // Step 2. Perform the action
        if (action === 'logout') {
            dispatch(setLogout());
        }
    };

    return (
        <AppBar sx={{position:"static", background:"none", boxShadow:"none"}}>

            <Toolbar sx={{ width:"100%", height:"70px", bgcolor:theme.palette.main[500], display:"flex", justifyContent:"space-between",
                           alignItems:"center", p:'1.5rem 1.5rem', borderBottom: `1px solid ${theme.palette.grey[300]}`}}>

                {/* LEFT SIDE, Logo */}
                <Box display="flex" alignItems="center" height="70px" maxWidth="100%" pl='2rem' pr='2rem' gap='1rem'>
                    <img src={logo} className="logoHover" alt="Logo" style={{ width: '50px', height: '50px'}} onClick={() => setIsSidebarOpen(!isSidebarOpen)} />
                    <Typography variant="h4" component="h4" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.5rem' }}>
                        HS One Advanced AI Research Lab
                    </Typography>
                </Box>

                {/* RIGHT SIDE, user info */}
                <Box display='flex' justifyContent='space-between' alignItems='center' gap="1.5rem">

                    {/* 3. Profile Image */}
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Button onClick={handleClick} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", textTransform: "none",
                                gap: "1rem", color: theme.palette.grey[100]}}>

                            {/* Name and Occupation */}
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                                <Typography fontWeight="bold" fontSize="1.0rem" >
                                    Annotator
                                </Typography>

                                <Typography fontWeight="bold" fontSize="1.0rem" >
                                    {user}
                                </Typography>
                            </Box>

                            {/* Arrow to Close */}
                            <ArrowDropDownOutlined sx={{ color: theme.palette.grey[300], fontSize: "30px" }} />
                        </Button>

                        {/* Dropdown */}
                        <Menu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}
                              sx={{
                                '& .MuiPaper-root': {
                                    minWidth: 220,
                                    color: 'text.secondary',
                                    '& .MuiMenu-list': {
                                        padding: '4px 0',
                                    },
                                },
                            }}
                        >
                            <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
                        </Menu>
                    </Box>

                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar
