import React, { useState, useEffect } from 'react';
import {
    Box, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Select,
    MenuItem, Typography, TextField, useTheme, Grid
} from '@mui/material';

// Contexts
import { useSidebar } from 'utils/SidebarProvider';
import { useDialog } from 'utils/DialogProvider';

// Custom Components
import CustomButton from 'components/common/CustomButton';
import CustomImageNavigation from 'components/common/CustomImageNavigation';
import CustomImageViewer from 'components/common/CustomImageViewer';

const Metadata = () => {
    const theme = useTheme();
    const [accessToken, setAccessToken] = useState('');
    const { metadata, setMetadata, updateMetadata, metadataCompletedList } = useSidebar();
    const { requestConfirm } = useDialog();

    // Define dental conditions
    const labels = [
        'Caries', 'Sarro', 'Lesion Periapical', 'Perdida Osea', 'Endodoncia',
        'Implante', 'Restos Radiculares', 'Incluidos', 'Corona', 'Pontico', 'Obturacion'
    ];

    // Initialize conditionChecks from comments
    const [conditionChecks, setConditionChecks] = useState(
        labels.reduce((acc, label) => ({ ...acc, [label]: metadata.comments?.includes(label) }), {})
    );

    useEffect(() => {
        // Update the checkbox state based on comments when component mounts or metadata changes
        const updatedChecks = labels.reduce((acc, label) => ({
            ...acc,
            [label]: metadata.comments?.includes(label)
        }), {});
        setConditionChecks(updatedChecks);
    }, [metadata.comments]); // Depend on metadata.comments to update checkbox states

    const handleSave = async () => {
        if (metadata.sex === '' || metadata.age === '' || metadata.smokes === '') {
            await requestConfirm('Error', 'Please fill all the fields before saving the data');
            return;
        }
        await updateMetadata(metadata);
    };

    const handleChange = (event) => {
        setMetadata({ ...metadata, [event.target.name]: event.target.value });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        // Update checkbox states
        setConditionChecks(prev => ({ ...prev, [name]: checked }));

        // Update comments field with new set of checked conditions
        const newComments = Object.entries({ ...conditionChecks, [name]: checked })
            .filter(([key, value]) => value)
            .map(([key]) => key)
            .join(', ');
        setMetadata({ ...metadata, comments: newComments });
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" flexGrow={1}>
            <CustomImageNavigation setAccessToken={setAccessToken} completedList={metadataCompletedList} />

            {accessToken !== '' && (
                <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1} gap="1rem" padding="1rem">
                    <CustomImageViewer accessToken={accessToken} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: '450px', height: '100%', padding: '1rem', gap: '1rem', bgcolor: 'white', borderRadius: '10px' }}>
                        
                        {/* Sex Selection */}
                        <FormControl component="fieldset" sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '0.25rem' }}>
                                <strong>Select the Sex</strong>
                            </Typography>
                            <RadioGroup
                                row
                                aria-label="sex"
                                name="sex"
                                value={metadata.sex}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                            </RadioGroup>
                        </FormControl>

                        {/* Age Selection */}
                        <FormControl fullWidth >
                            <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '0.5rem' }}>
                                <strong>Select the Age</strong>
                            </Typography>
                            <Select
                                id="age-select"
                                name="age"
                                value={metadata.age}
                                onChange={handleChange}
                            >
                                {[...Array(101).keys()].map((ageOption) => (
                                    <MenuItem key={ageOption} value={ageOption}>
                                        {ageOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Smoking Status */}
                        <FormControl component="fieldset" sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '0.5rem' }}>
                                <strong>Select if Smokes</strong>
                            </Typography>
                            <RadioGroup
                                row
                                aria-label="smokes"
                                name="smokes"
                                value={metadata.smokes}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                                <FormControlLabel value="unknown" control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>

                        {/* Checkboxes for dental conditions */}
                        <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '0.5rem' }}>
                            <strong>Select Pathologies</strong>
                        </Typography>
                        <Grid container spacing={1}>
                            {labels.map(label => (
                                <Grid item xs={6} sm={4} md={4} key={label}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={conditionChecks[label]}
                                                onChange={handleCheckboxChange}
                                                name={label}
                                            />
                                        }
                                        label={label}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        {/* Comments Input */}
                        <TextField
                            multiline
                            variant="outlined"
                            name="comments"
                            value={metadata.comments}
                            onChange={handleChange}
                            sx={{ width: '100%', flex: 1, minHeight: '100px' }}
                            InputProps={{ sx: { height: '100%', alignItems: 'flex-start', overflow: 'auto' } }}
                        />

                        <CustomButton onClick={handleSave} buttonText="Save" />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Metadata;
