import { useState, useEffect } from 'react';

// From the API
import { useDispatch, useSelector } from "react-redux";
import { useFetchProjectsMutation } from "services/api";
import { setLogout } from "utils/State"

// Custom Hook to fetch the projects
function useFetchProjects() {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [fetchProjects] = useFetchProjectsMutation();
    const dispatch = useDispatch();

    // States
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refreshProject = () => {
        setRefreshIndex((prevRefreshIndex) => prevRefreshIndex + 1);
    }

    // Fetch the projects
    useEffect(() => {

        // Function to fetch the projects just once at the beginning
        const fetchData = async () => {
            setLoading(true);
            // Ensure the user and the token are available
            if (user && token) {
                try {
                    // Fetch the list of projects availables for this user
                    const result = await fetchProjects({ token: token, requestBody: { email: user } }).unwrap();
                    if (result.success) {
                        setProjects(result.data);
                        console.log('Projects fetched:', result.data);
                    } else {
                        setProjects([]);
                        console.error('Error fetching projects:', result.error);
                        if (result.error === 401) {
                            dispatch(setLogout());
                        }
                    }
                } catch (error) {
                    console.error('Error fetching projects:', error);
                    if (error.status === 401) {
                        dispatch(setLogout());
                    }
                }
            }
            setLoading(false);
        };
        fetchData();
    }, [user, token, refreshIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return { projects, loading, refreshProject };
}

export default useFetchProjects;