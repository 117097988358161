// From the API
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetchNumberOfImagesMutation } from 'services/api';

// Custom Hook to fetch the projects
function useFetchNumberOfImages(projects, activeProject) {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [ fetchNumberOfImages ] = useFetchNumberOfImagesMutation();

    // State
    const [numberOfImages, setNumberOfImages] = useState(0);
    const [refreshIndex, setRefreshIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    // Function to refresh the number of metadata
    const refreshNumberOfImages = () => {
        setRefreshIndex((prevRefreshIndex) => prevRefreshIndex + 1);
    };

    useEffect(() => {
        // Function to update the number of images
        const fetchNumber = async () => {
            setLoading(true);
            try {
                const projectObject = projects[activeProject];
                const projectName = Object.keys(projectObject)[0];

                // Get the number of metadata files in the directory
                const response = await fetchNumberOfImages({ token: token, requestBody: { email: user, projectName: projectName } }).unwrap();
                if (response.success) {
                    setNumberOfImages(response.number);
                } else {
                    console.log('Error updating images');
                }
            } catch (error) {
                console.log('Error updating images');
            }
            setLoading(false);
        };
        if (projects.length > 0){
            fetchNumber();
        }
    }, [projects, activeProject, refreshIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return { numberOfImages, refreshNumberOfImages, loading };
}

export default useFetchNumberOfImages;