import { useState } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useUpdateImageMetadataMutation } from 'services/api';

// Custom Hook to fetch the projects
function useUpdateMetadata(projects, activeProject, imageList, activeImage, refreshNumberOfMetadata) {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [ updateImageMetadata ] = useUpdateImageMetadataMutation();
    const [updating, setUpdating] = useState(false);

    // Function to update the metadata
    const updateMetadata = async (metadata) => {
        setUpdating(true);
        try {
            const projectObject = projects[activeProject];
            const projectName = Object.keys(projectObject)[0];
            const requestBody = { email: user, projectName:projectName, imageName: imageList[activeImage].fileName,
                                sex: metadata.sex, age: metadata.age, smokes: metadata.smokes, comments: metadata.comments };

            // Fetch the list of projects availables for this user
            const result = await updateImageMetadata({ token: token, requestBody: requestBody }).unwrap();
            if (result.success) {
                // Update the number of metadata files
                console.log('Metadata updated');
                refreshNumberOfMetadata();
            } else {
                console.log('Error updating metadata');
            }
        } catch (error) {
            console.log('Error updating metadata');
        }
        setUpdating(false);
    }

    return { updateMetadata, updating };
}

export default useUpdateMetadata;