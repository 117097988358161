import React, { createContext, useContext, useState, useEffect, Fragment } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';


// Custom Hooks
import useFetchProjects from './Hooks/useFetchProjects';
import useFetchTokens from './Hooks/useFetchTokens';
import useUpdateProjectFile from './Hooks/useUpdateProjectFile';
import useFetchMetadata from './Hooks/useFetchMetadata';
import useUpdateMetadata from './Hooks/useUpdateMetadata';
import useFetchNumberOfMetadata from './Hooks/useFetchNumberOfMetadata';
import useFetchNumberOfImages from './Hooks/useFetchNumberOfImages';
import useUpdateImage from './Hooks/useUpdateImage';
import useFetchNumberOfAnnotations from './Hooks/useFetchNumberOfAnnotations';
import useFetchAnnotations from './Hooks/useFetchAnnotations';
import useUpdateAnnotations from './Hooks/useUpdateAnnotations';

// Create a new context for the Sidebar
const SidebarContext = createContext();

// Export the custom hook for using the Sidebar Context
export const useSidebar = () => useContext(SidebarContext);

// Context Provider Component
export const SidebarProvider = ({ children }) => {

    // States
    const [activeProject, setActiveProject] = useState(0);
    const [activeImage, setActiveImage] = useState(0);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // Custom Hooks
    const { projects, loading: fetchProjectsLoading, refreshProject } = useFetchProjects();
    const { imageList, labels, refreshTokens, loading: fetchTokensLoading } = useFetchTokens(projects, activeProject);
    const { metadata, setMetadata, loading: fetchMetadataLoading } = useFetchMetadata(projects, activeProject, imageList, activeImage);
    const { annotations, setAnnotations, loading: fetchAnnotationsLoading} = useFetchAnnotations(projects, activeProject, imageList, activeImage);
    const { numberOfImages, refreshNumberOfImages, loading: fetchNumberOfImagesLoading} = useFetchNumberOfImages(projects, activeProject);
    const { numberOfMetadata, metadataCompletedList, refreshNumberOfMetadata, loading: fetchNumberOfMetadataLoading} = useFetchNumberOfMetadata(projects, activeProject);
    const { numberOfAnnotations, annotationCompletedList, refreshNumberOfAnnotations, loading: fetchNumberOfAnnotationsLoading} = useFetchNumberOfAnnotations(projects, activeProject);
    const { updateMetadata, updating: updateMetadataLoading} = useUpdateMetadata(projects, activeProject, imageList, activeImage, refreshNumberOfMetadata);
    const { updateAnnotations, updating: updateAnnotationsLoading} = useUpdateAnnotations(projects, activeProject, imageList, activeImage, refreshNumberOfAnnotations);
    const { uploadImageFile, updating: updateImageLoading} = useUpdateImage(projects, activeProject);
    const { updateCompletedSteps, steps, activeStep, setActiveStep, updating: updateProjectFileLoading} = useUpdateProjectFile(projects, activeProject, numberOfImages, numberOfMetadata);

    const anyLoading = fetchProjectsLoading ||
                       fetchTokensLoading ||
                       fetchMetadataLoading ||
                       fetchAnnotationsLoading ||
                       fetchNumberOfImagesLoading ||
                       fetchNumberOfMetadataLoading ||
                       fetchNumberOfAnnotationsLoading ||
                       updateMetadataLoading ||
                       updateAnnotationsLoading ||
                       updateProjectFileLoading ||
                       isDeleting;

    useEffect(() => {
        if (anyLoading) {
            setIsUpdating(true);
        } else if (!anyLoading) {
            // Wait for 2 seconds after loading completes before hiding the Backdrop
            const timer = setTimeout(() => setIsUpdating(false), 4000);
            return () => clearTimeout(timer);
        }
    }, [anyLoading]);


    const value = {
        steps,
        activeStep,
        setActiveStep,
        projects,
        activeProject,
        labels,
        setActiveProject,
        updateCompletedSteps,
        setIsUpdating,
        imageList,
        activeImage,
        setActiveImage,
        metadata,
        setMetadata,
        updateMetadata,
        numberOfMetadata,
        numberOfImages,
        numberOfAnnotations,
        uploadImageFile,
        refreshNumberOfImages,
        annotations,
        setAnnotations,
        updateAnnotations,
        refreshTokens,
        metadataCompletedList,
        annotationCompletedList,
        refreshProject,
        setIsDeleting
    };

    return (
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
                open={isUpdating}
                onClick={() => setIsUpdating(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <SidebarContext.Provider value={value}>
                {children}
            </SidebarContext.Provider>
        </Fragment>

    );
};
