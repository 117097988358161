import { useState, useEffect } from "react";

// From the API
import { useSelector } from "react-redux";
import { useFetchImageMetadataMutation } from "services/api";

// Custom Hook to fetch the projects
function useFetchMetadata(projects, activeProject, imageList, activeImage) {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [ fetchImageMetadata ] = useFetchImageMetadataMutation();
    const [metadata, setMetadata] = useState({sex: '', age: '', smokes: '', comments: ''});
    const [loading, setLoading] = useState(false);

    // Function to update sex, age, smokes and comments based on the activeImage and project
    useEffect(() => {

        // Function to fetch the metadata
        const fetchMetadata = async () => {
            setLoading(true);
            try {
                const projectObject = projects[activeProject];
                const projectName = Object.keys(projectObject)[0];

                // Fetch the list of projects availables for this user
                const requestBody = { email: user, projectName:projectName, imageName: imageList[activeImage].fileName };
                const result = await fetchImageMetadata({ token: token, requestBody: requestBody }).unwrap();
                console.log('Result:', result);
                if (result.success) {
                    setMetadata({sex: result.sex, age: result.age, smokes: result.smokes, comments: result.comments});
                } else {
                    setMetadata({sex : '', age: '', smokes: '', comments: ''});
                }
            } catch (error) {
                setMetadata({sex : '', age: '', smokes: '', comments: ''});
            }
            setLoading(false);
        };

        // If imageList is not empty
        if (imageList.length > 0) {
            fetchMetadata();
        }
    }, [activeProject, projects, activeImage, imageList]); // eslint-disable-line react-hooks/exhaustive-deps

    return { metadata, setMetadata, loading };
}

export default useFetchMetadata;