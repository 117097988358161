// React and MUI imports
import React, { useRef, useState, useEffect } from 'react';
import { Box, Paper, useTheme, } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import RemoveIcon from '@mui/icons-material/RemoveCircleRounded';

// Konva
import Konva from 'konva';
import { Stage, Layer, Image as KonvaImage, Line, Circle } from 'react-konva';
import useImage from 'use-image';
import simplify from 'simplify-js';
import { useDialog } from 'utils/DialogProvider';

// API
import { useSelector } from "react-redux";
import { useFetchImageDataMutation } from 'services/api';

// Import toolbar
import CustomOptionBar from './CustomOptionBar';

// Image Layer with Memo to avoid re-rendering
const ImageLayer = React.memo(({ image, imageRef, brightness, contrast }) => {
    return (
        <Layer>
            <KonvaImage
                ref={imageRef}
                image={image}
                filters={[Konva.Filters.Brighten, Konva.Filters.Contrast]}
                brightness={brightness}
                contrast={contrast}
            />
        </Layer>
    );
});

// Random ID generator (based on the current time)
const generateId = () => {
    return Date.now().toString();
};

// Function to convert hex to rgba
function hexToRGBA(hex, alpha = 0.5) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let r, g, b;
    if (hex.length === 3) {
        // If it's a shorthand hex color
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
        // If it's a full hex color
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    } else {
        throw new Error("Invalid hex color: " + hex);
    }

    // Return the rgba() formatted color
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Image Editor component
const ImageEditor = ( { accessToken, annotations, setAnnotations, labels, handleSave } ) => {
    const theme = useTheme();
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [image, setImage] = useState(null);
    const [fetchImageData] = useFetchImageDataMutation();
    const { requestConfirm } = useDialog();

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const result = await fetchImageData({ token: token, requestBody: { accessToken: accessToken } }).unwrap();
                if (result.data) {
                    const img = new Image();
                    img.onload = () => {
                        setImage(img);
                    };
                    img.onerror = (error) => {
                        console.error('Error loading image:', error);
                    };
                    img.src = result.data;
                }
            } catch (error) {
                console.error('Error fetching image data', error);
            }
        };

        if (accessToken) {
            fetchImage();
        }
    }, [accessToken, fetchImageData, token]);


    // Refs for the stage, paper, and image
    const stageRef = useRef(null);
    const paperRef = useRef(null);
    const imageRef = useRef(null);

    // Sate size and original dimensions
    const [stageSize, setStageSize] = useState({ width: 500, height: 100 });

    // Editor initial state
    const [enableIndex, setEnableIndex] = useState(0);
    const initialState = {
        scale: 1,
        position: { x: 0, y: 0 },
        drawing: false,
        lines: [],
        shapes: [],
        currentMode: 'NONE',
        brightness: 0,
        contrast: 0,
        strokeSize: 2,
        selectedLabel: { 'label': '', 'color': '#000000' },
        tooltipOpen: false,
        tooltipContent : '',
        tooltipPosition: { x: 0, y: 0 },
        highlightedShapeId: null,
        toolbarDisabled: true,
        visibility: true,
        tempLine: null,
    };
    const [state, setState] = useState(initialState);

    // Function to update the state
    const updateState = (newValues) => {
        setState((prevState) => ({
            ...prevState,
            ...newValues,
        }));
    };

    // Place the image in the center of the canvas at the start
    useEffect(() => {
        if (image && stageRef.current) {
            // Determine scale to fit the image in the canvas, maintaining aspect ratio
            const scaleX = stageSize.width / image.width;
            const scaleY = stageSize.height / image.height;
            const scaleToFit = Math.min(scaleX, scaleY);

            // Calculate centered position based on scaled image size
            const centeredX = (stageSize.width - (image.width * scaleToFit)) / 2;
            const centeredY = (stageSize.height - (image.height * scaleToFit)) / 2;

            updateState({
                scale: scaleToFit,
                position: { x: centeredX, y: centeredY },
                originalDimensions: { width: image.width, height: image.height },
            });

            // Apply transformations to stage for immediate visual update
            stageRef.current.scale({ x: scaleToFit, y: scaleToFit });
            stageRef.current.position({ x: centeredX, y: centeredY });
            stageRef.current.batchDraw();
        }
    }, [image, stageRef]); // eslint-disable-line react-hooks/exhaustive-deps

    // Cache the image when it's loaded (for brightness and contrast filters)
    useEffect(() => {
        if (image) {
            imageRef.current.cache();
            imageRef.current.getLayer().batchDraw();

            // Enable the toolbar
            updateState({ toolbarDisabled: false });
        }
    }, [image]);

    // Update the stage size when the paper ref changes
    useEffect(() => {
        const updateStageSize = () => {
            if (paperRef.current) {
                setStageSize({
                    width: paperRef.current.offsetWidth,
                    height: paperRef.current.offsetHeight,
                });
            }
        };

        // Update stage size initially
        updateStageSize();
        window.addEventListener('resize', updateStageSize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', updateStageSize);
    }, []);

    // Initialize the shapes based on the current image
    useEffect(() => {
        if (annotations.length > 0) {
            updateState({ shapes: annotations });
        }
        else{
            updateState({ shapes: [] });
        }

    }, [annotations]);

    // Zoom in and out with the mouse wheel
    const handleWheel = (e) => {
        if (state.currentMode !== 'PANNING') return; // Only zoom if panning is enabled

        e.evt.preventDefault();
        const scaleBy = e.evt.deltaY > 0 ? 0.9 : 1.1; // Faster zoom on wheel scroll

        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        const newScale = oldScale * scaleBy;

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };

        // Set the new scale and position
        updateState({ scale: newScale });
        updateState({ position: newPos });
    };

    const getPointerPos = (e) => {
        const stage = stageRef.current.getStage();
        const pos = stage.getPointerPosition();
        return {
            x: (pos.x - stage.x()) / stage.scaleX(),
            y: (pos.y - stage.y()) / stage.scaleY(),
        };
    };

    const finalizeLine = (event) => {

        console.log(event);

        // If event was trigger by a mouse click, delete the last point
        let points = state.tempLine.points;
        if (event.type === 'click') {
            points = state.tempLine.points.slice(0, -2);
        }

        const newLine = {
            type: 'line',
            id: generateId(),
            label: state.selectedLabel.label,
            points: points,
            stroke: state.selectedLabel.color,
            strokeWidth: state.strokeSize,
        };

        // Add the new line to the shapes array
        updateState({
            shapes: [...state.shapes, newLine],
            tempLine: null,  // Clear the temporary line data
        });

        setAnnotations([...annotations, newLine]);
        setEnableIndex(enableIndex + 1);
    };

    // Mouse down handlers
    const handlePointerDown = (e) => {
        if (state.currentMode === 'DRAWING') {
            updateState({ drawing: true });
            // Get the current stage
            const stage = stageRef.current.getStage();
            // Get the mouse position relative to the stage
            const pos = stage.getPointerPosition();
            // Adjust the position based on the stage's scale and position
            const adjustedPos = {
                x: (pos.x - stage.x()) / stage.scaleX(),
                y: (pos.y - stage.y()) / stage.scaleX(), // Assuming uniform scaling for simplicity
            };
            updateState({ lines: [...state.lines, { points: [adjustedPos.x, adjustedPos.y] }] });

        } else if (state.currentMode === 'LINE') {
            if (!state.tempLine) {
                // Start a new line
                const startPos = getPointerPos(e);
                updateState({ tempLine: { points: [startPos.x, startPos.y] } });
            } else {
                // Add new point to the existing line
                const newPos = getPointerPos(e);
                const newPoints = [...state.tempLine.points, newPos.x, newPos.y];
                updateState({ tempLine: { points: newPoints } });
            }
        }
    };

    // Mouse move handlers
    const handlePointerMove = (e) => {
        if (state.currentMode === 'LINE' && state.tempLine) {
            const newPos = getPointerPos(e);
            let newPoints;

            if (state.tempLine.points.length === 2) {
                newPoints = [...state.tempLine.points, newPos.x, newPos.y];
            } else {
                newPoints = [...state.tempLine.points.slice(0, -2), newPos.x, newPos.y];
            }

            updateState({ tempLine: { ...state.tempLine, points: newPoints } });
        }

        if (!state.drawing || state.currentMode !== 'DRAWING') return;

        requestAnimationFrame(() => {
            const stage = stageRef.current.getStage();
            const point = stage.getPointerPosition();
            // Adjust the point based on the stage's transformation
            const adjustedPoint = {
                x: (point.x - stage.x()) / stage.scaleX(),
                y: (point.y - stage.y()) / stage.scaleX(),
            };

            let lastLine = state.lines[state.lines.length - 1];
            // Update the last line with the new, adjusted point
            lastLine.points = lastLine.points.concat([adjustedPoint.x, adjustedPoint.y]);
            state.lines.splice(state.lines.length - 1, 1, lastLine);
            updateState({ lines: state.lines.concat() });
        });
    };

    // Mouse up handler
    const handlePointerUp = () => {
        if (state.currentMode === 'LINE' && state.tempLine) {
            const newPos = getPointerPos();
            const newPoints = [...state.tempLine.points, newPos.x, newPos.y];
            updateState({ tempLine: { ...state.tempLine, points: newPoints } });
        }

        if (state.currentMode === 'LINE' && state.tempLine) return; // Do not finalize the line on mouse up
        updateState({ drawing: false });
    };

    // Finalize the shape and close it
    const finalizeShape = () => {
        if (state.lines.length === 0) return;

        // Accumulate all points from the drawn lines
        let allPoints = [];
        state.lines.forEach(line => {
            // Convert points to {x, y} format for simplify-js
            for (let i = 0; i < line.points.length; i += 2) {
                allPoints.push({ x: line.points[i], y: line.points[i + 1] });
            }
        });

        // Simplify the points
        const tolerance = 0.1;
        const simplifiedPoints = simplify(allPoints, tolerance, true);

        // Optionally, ensure the shape is closed by adding the start point at the end
        if (simplifiedPoints.length > 1 &&
            (simplifiedPoints[0].x !== simplifiedPoints[simplifiedPoints.length - 1].x ||
            simplifiedPoints[0].y !== simplifiedPoints[simplifiedPoints.length - 1].y)) {
            simplifiedPoints.push(simplifiedPoints[0]);
        }

        // Convert points back to the flat format expected by Konva
        const pointsForShape = simplifiedPoints.flatMap(p => [p.x, p.y]);

        // Calculate the widht and height of the annotation
        let xs = [];
        let ys = [];

        // Extracting x and y values separately
        for (let i = 0; i < pointsForShape.length; i += 2) {
            xs.push(pointsForShape[i]);
            ys.push(pointsForShape[i + 1]);
        }

        const minX = Math.min(...xs);
        const maxX = Math.max(...xs);
        const minY = Math.min(...ys);
        const maxY = Math.max(...ys);

        // Compute and round width and height if not defined
        const width = Math.round(maxX - minX);
        const height = Math.round(maxY - minY);

        // Create the new shape with the simplified points
        const newShape = {
            id: generateId(),
            label: state.selectedLabel.label,
            stroke: state.selectedLabel.color,
            strokeWidth: state.strokeSize,
            fill: hexToRGBA(state.selectedLabel.color, 0.5),
            points: pointsForShape,
            tags: [],
            notes: '',
            createdAt: new Date().toISOString(),
            author: user.email,
            width: width,
            height: height,
            bbox: [minX, minY, maxX, maxY],
        };

        // Add the new shape to your collection of shapes
        updateState({ shapes: [...state.shapes, newShape] });

        // Clear the lines as they are no longer needed
        updateState({ lines: [] });

        // Add the new shape to the annotations array on currentImage
        setAnnotations([...annotations, newShape]);
    };

    // Shape click handler
    const handleShapeClick = (shapeId) => {
        // If in delete mode, delete the clicked shape
        if (state.currentMode === 'DELETE') {
            // Remove the shape from the shapes array and update the state
            updateState({
                shapes: state.shapes.filter(shape => shape.id !== shapeId),
                highlightedShapeId: null
            });

            // Remove the shape from the annotations array on currentImage
            setAnnotations(annotations.filter(annotation => annotation.id !== shapeId));
        }
    };

    // Download image functionality
    const downloadImage = () => {
        // Store the current stage settings
        const oldDimensions = { width: stageRef.current.width(), height: stageRef.current.height() };
        const oldScale = { x: stageRef.current.scaleX(), y: stageRef.current.scaleY() };
        const oldPosition = { x: stageRef.current.x(), y: stageRef.current.y() };

        // Temporarily adjust the stage to match the original image size
        stageRef.current.width(state.originalDimensions.width);
        stageRef.current.height(state.originalDimensions.height);
        stageRef.current.scaleX(1);
        stageRef.current.scaleY(1);
        stageRef.current.x(0);
        stageRef.current.y(0);
        stageRef.current.draw(); // Make sure the stage is redrawn with these settings

        // Export the image
        const dataURL = stageRef.current.toDataURL();
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'AnotiaImage.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revert the stage to its original settings
        stageRef.current.width(oldDimensions.width);
        stageRef.current.height(oldDimensions.height);
        stageRef.current.scaleX(oldScale.x);
        stageRef.current.scaleY(oldScale.y);
        stageRef.current.x(oldPosition.x);
        stageRef.current.y(oldPosition.y);
        stageRef.current.batchDraw(); // Redraw the stage with the original settings
    };

    const zoomIn = () => {
        const newScale = state.scale * 1.1; // Increase scale by 10%
        updateState({
            scale: newScale,
            position: {
                x: state.position.x - (stageSize.width * 0.05), // Adjust position to zoom towards center
                y: state.position.y - (stageSize.height * 0.05),
            }
        });
    };

    const zoomOut = () => {
        if (state.scale > 0.1) { // Prevent zooming out too much
            const newScale = state.scale * 0.9; // Decrease scale by 10%
            updateState({
                scale: newScale,
                position: {
                    x: state.position.x + (stageSize.width * 0.05), // Adjust position to zoom towards center
                    y: state.position.y + (stageSize.height * 0.05),
                }
            });
        }
    };


    let animationFrameId = null;
    const handleTouchMove = (e) => {
        e.evt.preventDefault();
        const stage = e.target.getStage();
        if (e.evt.touches.length === 2) {
            const touch1 = { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY };
            const touch2 = { x: e.evt.touches[1].clientX, y: e.evt.touches[1].clientY };

            const distance = Math.sqrt(Math.pow(touch2.x - touch1.x, 2) + Math.pow(touch2.y - touch1.y, 2));

            if (state.lastDist) {
                const scaleBy = distance / state.lastDist;
                const newScale = state.scale * scaleBy;

                const centerX = (touch1.x + touch2.x) / 2 - stage.container().getBoundingClientRect().left;
                const centerY = (touch1.y + touch2.y) / 2 - stage.container().getBoundingClientRect().top;

                if (animationFrameId) {
                    cancelAnimationFrame(animationFrameId);
                }

                animationFrameId = requestAnimationFrame(() => {
                    const newPos = {
                        x: stage.x() - (centerX - stage.x()) * (scaleBy - 1),
                        y: stage.y() - (centerY - stage.y()) * (scaleBy - 1),
                    };

                    updateState({ scale: newScale, position: newPos, lastDist: distance });
                });
            } else {
                updateState({ lastDist: distance });
            }
        }
    };

    const handleTouchStart = (e) => {
        if (e.evt.touches.length === 2) {
            const touch1 = { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY };
            const touch2 = { x: e.evt.touches[1].clientX, y: e.evt.touches[1].clientY };
            const distance = Math.sqrt(Math.pow(touch2.x - touch1.x, 2) + Math.pow(touch2.y - touch1.y, 2));
            updateState({ lastDist: distance });
        }
    };

    const handleTouchEnd = (e) => {
        if (e.evt.touches.length < 2) {
            updateState({ lastDist: null });
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        }
    };

    // Delete all the annotations of the current label
    const deleteAnnotations = async () => {
        // Request confirmation
        const result = await requestConfirm('Delete Annotations', `Are you sure you want to delete all annotations of ${state.selectedLabel.label}?`);
        if (!result) return;

        // Remove the shapes from the shapes array and update the state
        updateState({
            shapes: state.shapes.filter(shape => shape.label !== state.selectedLabel.label),
            highlightedShapeId: null
        });

        // Remove the shapes from the annotations array on currentImage
        setAnnotations(annotations.filter(annotation => annotation.label !== state.selectedLabel.label));
    };

    // Return the component
    return (
        <Paper
            elevation={3}
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection:'column',
                flexGrow: 1,
                overflow: 'hidden',
                backgroundColor:theme.palette.secondary[100],
                border: "1px solid #ccc",
                borderRadius: '10px',
                position: 'relative',
            }}
        >
            {/* Image Option Bar */}
            <CustomOptionBar
                state={state}
                updateState={updateState}
                downloadImage={downloadImage}
                finalizeShape={finalizeShape}
                labels={labels}
                handleSave={handleSave}
                enableIndex={enableIndex}
                finalizeLine={finalizeLine}
                deleteAnnotations={deleteAnnotations}
            />

            <Box ref={paperRef} flexGrow={1} sx={{ position: 'relative' }} >

                <IconButton onClick={zoomIn} color="primary" sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1000}}>
                    <AddIcon />
                </IconButton>

                <IconButton onClick={zoomOut} color="primary" sx={{ position: 'absolute', top: 60, right: 10, zIndex: 1000 }}>
                    <RemoveIcon />
                </IconButton>

                <Stage
                    ref={stageRef}
                    width={stageSize.width}
                    height={stageSize.height}
                    scaleX={state.scale}
                    scaleY={state.scale}
                    x={state.position.x}
                    y={state.position.y}
                    draggable={state.currentMode === 'PANNING'}
                    onWheel={handleWheel}
                    onPointerDown={handlePointerDown}
                    onPointerMove={handlePointerMove}
                    onPointerUp={handlePointerUp}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    onDragEnd={() => {updateState({ position: { x: stageRef.current.x(), y: stageRef.current.y() } });}}
                >
                    <ImageLayer image={image} imageRef={imageRef} stageSize={stageSize} brightness={state.brightness} contrast={state.contrast} />

                    <Layer>
                        {state.lines.map((line, i) => (
                            <Line
                                key={i}
                                points={line.points}
                                stroke={state.selectedLabel.color}
                                strokeWidth={state.strokeSize}
                                tension={0.0}
                                lineCap="round"
                                globalCompositeOperation="source-over"
                            />
                        ))}
                    </Layer>

                    <Layer>
                        {state.shapes.map((shape) => {
                            // If visibility is false, do not render the shape
                            if (!state.visibility) return null;

                            // If the label does not match the selected label, do not render the shape
                            if (state.selectedLabel.label !== '' && shape.label !== state.selectedLabel.label) return null;

                            // If the shape is type === 'line', render it as a line
                            if (shape.type === 'line') {
                                return (
                                    <Line key={shape.id} points={shape.points} stroke={shape.stroke} strokeWidth={shape.strokeWidth} tension={0.0} lineCap="round"
                                        onClick={() => handleShapeClick(shape.id)} onTouchStart={() => handleShapeClick(shape.id)}/>
                                );
                            }

                            // Render the shape
                            return (
                                <Line key={shape.id} points={shape.points} stroke={shape.stroke} strokeWidth={shape.strokeWidth} closed={true} fill={shape.fill}
                                    onClick={() => handleShapeClick(shape.id)} onTouchStart={() => handleShapeClick(shape.id)}/>
                            );
                        })}
                    </Layer>

                    <Layer>
                    {state.tempLine && (
                        <>
                            <Line
                                points={state.tempLine.points}
                                stroke={state.selectedLabel.color}
                                strokeWidth={state.strokeSize}
                                lineCap="round"
                                lineJoin="round"
                            />
                            {state.tempLine.points.map((_, index) =>
                                index % 2 === 0 && (
                                    <Circle
                                        key={index}
                                        x={state.tempLine.points[index]}
                                        y={state.tempLine.points[index + 1]}
                                        radius={state.strokeSize * 2}
                                        fill={state.selectedLabel.color}
                                    />
                                )
                            )}
                            {/* Add a circle at the current moving end of the line */}
                            {state.tempLine.points.length >= 2 && (
                                <Circle
                                    x={state.tempLine.points[state.tempLine.points.length - 2]}
                                    y={state.tempLine.points[state.tempLine.points.length - 1]}
                                    radius={state.strokeSize * 2}
                                    fill={state.selectedLabel.color}
                                />
                            )}
                        </>
                    )}
                    </Layer>


                </Stage>

            </Box>

        </Paper>
    );
}

export default ImageEditor;