import React from 'react';
import { TextField } from '@mui/material';

const CustomTextField = ({ label, name, value, onChange, onBlur, error, type, helperText, sx }) => {

    return(
        <TextField
            label={label}
            variant="outlined"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            name={name}
            error={error}
            helperText={helperText}
            type={type}
            sx={{
                width: '40vw',
                maxWidth: '30rem',
                ...sx
            }}
        />
    );
};

export default CustomTextField;