import React, { useState, useRef } from 'react';
import { Box, Button, Typography, Paper, List, ListItem, ListItemText, ListItemIcon, LinearProgress, IconButton, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';

// Icons
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// Contexts
import { useSidebar } from 'utils/SidebarProvider'

// Custom Components
import CustomButton from 'components/common/CustomButton';

const UploadImages = () => {
    // States
    const theme = useTheme();
    const inputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const {uploadImageFile, refreshNumberOfImages, refreshTokens} = useSidebar();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/bmp': ['.bmp'],
            'image/gif': ['.gif'],
            'image/webp': ['.webp'],
            'image/tiff': ['.tiff', '.tif']
        },

        onDrop: (acceptedFiles) => {
            const newFiles = acceptedFiles.map(file => ({
                file,
                preview: URL.createObjectURL(file),
                name: file.name,
                size: file.size,
                status: 'pending', // Initial status for new files
            }));
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
        },
    });

    const removeFile = (fileName) => {
        // Filter out the file to remove
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
        // Update uploadProgress state to remove the entry for the removed file
        setUploadProgress(prevProgress => {
            const newProgress = { ...prevProgress };
            delete newProgress[fileName];
            return newProgress;
        });
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const onAllUploadsComplete = async () => {
        refreshTokens();
        refreshNumberOfImages();
    }

    const startUploads = async (index = 0) => {
        if (index < files.length) {
            // Step 1. Get the file to upload
            const file = files[index];

            // Step 2. Update the UI to indicate that the file is being uploaded
            setUploadProgress(prevProgress => ({...prevProgress, [file.name]: 'uploading'}));

            // Step 3. Send the file to the server
            const result = await uploadImageFile(file);
            if(result){
                setUploadProgress(prevProgress => ({...prevProgress, [file.name]: 'success'}));
            } else {
                setUploadProgress(prevProgress => ({...prevProgress, [file.name]: 'error'}));
            }

            // Step 4. Proceed to the next file
            if (index === files.length - 1) {
                onAllUploadsComplete();
            } else {
                startUploads(index + 1);
            }
        } else {
            onAllUploadsComplete();
        }
    };

    const calculateOverallProgress = () => {
        const progressValues = Object.keys(uploadProgress).map(key => uploadProgress[key] === 'success' ? 1 : 0);
        const totalSuccess = progressValues.reduce((acc, cur) => acc + cur, 0);
        return files.length > 0 ? (totalSuccess / files.length) * 100 : 0;
    };

    // Render the component
    return(
        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>

                <Paper
                    elevation={20}
                    style={{ width: '80vw', maxWidth: '50rem', height: '35rem', maxHeight: '60rem', borderRadius: '1rem', display: 'flex',
                             flexDirection: 'column', alignItems: 'center', backgroundColor:'white',
                             boxShadow: `8px 8px 30px ${theme.palette.black[900]}`
                    }}
                >

                {/* Title */}
                <Typography variant="h3" sx={{ color: theme.palette.main[500], fontWeight: 'bold', padding:'1rem', mb:'0rem'}}>
                    Upload Images
                </Typography>

                <Paper {...getRootProps()} sx={{
                    padding: '1rem',
                    border: '2px dashed',
                    borderColor: isDragActive ? theme.palette.primary.main : '#eeeeee',
                    bgcolor: isDragActive ? theme.palette.action.hover : '#ffffff',
                    color: theme.palette.text.secondary,
                    textAlign: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '15vh',
                    width: '90%',
                }}>
                    <input {...getInputProps()} ref={inputRef} />
                    <Typography variant="h5" sx={{paddingBottom:'1rem'}}>
                        {isDragActive ? 'Drop the files here ...' : 'Drag \'n\' drop some files here, or click to select files'}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleButtonClick}>
                        Select Files
                    </Button>
                </Paper>

                <List sx={{ mt: 2, overflowY: 'auto', height:'27vh', width:'90%' }}>
                    {files.map((file, index) => (
                        <ListItem
                            key={index}
                            sx={{ bgcolor: 'action.hover', mb: 1, borderRadius: '5px', display: 'flex', alignItems: 'center' }}
                        >
                            <ListItemIcon>
                                {file.file.type.startsWith('image/') ? <ImageIcon /> : <InsertDriveFileIcon />}
                            </ListItemIcon>
                            <ListItemText primary={file.name} secondary={`${(file.size / 1024).toFixed(2)} KB`} />
                            {uploadProgress[file.name] === 'success' ? (
                                <CheckCircleIcon color="success" />
                            ) : uploadProgress[file.name] === 'error' ? (
                                <ErrorIcon color="error" />
                            ) : uploadProgress[file.name] === 'uploading' ? (
                                <Typography variant="body2">Uploading...</Typography>
                            ) : (
                                <>
                                    <Typography variant="body2">Pending</Typography>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => removeFile(file.name)}
                                        sx={{ marginLeft: 'auto' }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
                <LinearProgress variant="determinate" value={calculateOverallProgress()} sx={{ width: '90%', mt: 2, height: '20px' }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: '1rem', gap:'2rem', width:'100%', pr:'2.5rem' }}>
                    <CustomButton onClick={() => {setFiles([]); setUploadProgress({});}} buttonText="Clear List" />
                    <CustomButton onClick={() => startUploads()} buttonText="Start Upload" />
                </Box>
            </Paper>

        </Box>
    );
};

export default UploadImages;