import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import globalReducer from 'utils/State';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "services/api";
import App from './App';

// Redux Persist
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

// Persist options
const persistConfig = { key: "root", storage, version: 1 };

// Include the persisted reducer and the API reducer
const rootReducer = {
    // Persisted reducer
    persistedReducer: persistReducer(persistConfig, globalReducer),

    // API reducer
    [api.reducerPath]: api.reducer,
};

// Create the store with the persisted reducer
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
        serializableCheck: {ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]}
        }).concat(api.middleware),
});

// Enable listeners for refetching data on focus/mutation by default
setupListeners(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistStore(store)}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
