import '../../../utils/Styles/background.css'
import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

// Components
import Navbar from "components/layout/Navbar"
import Sidebar from "components/layout/Sidebar"

const Layout = () => {
    // States
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const sideBarWidth = '20vw';

    useEffect(() => {
        console.log(isSidebarOpen)
    }, [isSidebarOpen])

    // Sidebar Context
    return (
        <Box className="gridBackground" display='flex' flexDirection='column' style={{ height: '100%' }}>

            {/* Navbar at the top */}
            <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} sideBarWidth={sideBarWidth} />

            {/* Container for Sidebar and main content */}
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', marginLeft: isSidebarOpen ? sideBarWidth : 0  }}>
                <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                <Outlet />
            </Box>
        </Box>
    );
};

export default Layout
