import { useState, useEffect } from "react";

// From the API
import { useSelector } from "react-redux";
import { useFetchImageAnnotationsMutation } from "services/api";

// Custom Hook to fetch the projects
function useFetchAnnotations(projects, activeProject, imageList, activeImage) {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [ fetchImageAnnotations ] = useFetchImageAnnotationsMutation();
    const [annotations, setAnnotations] = useState([]);
    const [loading, setLoading] = useState(false);

    // Function to update sex, age, smokes and comments based on the activeImage and project
    useEffect(() => {

        // Function to fetch the Annotations
        const fetchAnnotations = async () => {
            setLoading(true);
            try {
                const projectObject = projects[activeProject];
                const projectName = Object.keys(projectObject)[0];

                // Fetch the list of projects availables for this user
                const requestBody = { email: user, projectName:projectName, imageName: imageList[activeImage].fileName };
                const result = await fetchImageAnnotations({ token: token, requestBody: requestBody }).unwrap();
                console.log('Result:', result);
                if (result.success) {
                    setAnnotations(result.annotations);
                } else {
                    setAnnotations([]);
                }
            } catch (error) {
                setAnnotations([]);
            }
            setLoading(false);
        };

        // If imageList is not empty
        if (imageList.length > 0) {
            fetchAnnotations();
        }
    }, [activeProject, projects, activeImage, imageList]); // eslint-disable-line react-hooks/exhaustive-deps

    return { annotations, setAnnotations, loading };
}

export default useFetchAnnotations;