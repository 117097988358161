import React, { createContext, useContext, useState, useCallback } from 'react';
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box } from '@mui/material';

// Create a new context
const DialogContext = createContext();

// Custom Hook for using the Dialog Context
export const useDialog = () => useContext(DialogContext);

// Custom Dialog Component
const CustomDialog = ({ open, title, alertText, onConfirm, onCancel }) => (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">

        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {alertText}
            </DialogContentText>
        </DialogContent>

        <DialogActions>
            <Box width="100%" display="flex" justifyContent="center" gap='2rem'>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onConfirm} autoFocus>Confirm</Button>
            </Box>
        </DialogActions>
    </Dialog>
);

// Custom Input Dialog Component
const CustomInputDialog = ({ open, title, prompt, onConfirm, onCancel }) => {
    const [inputValue, setInputValue] = useState('');

    const handleClose = () => {
        onCancel();
        setInputValue('');
    };

    const handleConfirm = () => {
        onConfirm(inputValue);
        setInputValue('');
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="input-dialog-title" sx={{ '& .MuiDialog-paper': { width: '30vw' } }}>
            <DialogTitle id="input-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="input"
                    label={prompt}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

// Custom Password Change Dialog Component
const CustomPasswordChangeDialog = ({ open, onConfirm, onCancel }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleClose = () => {
        onCancel();
        setPassword('');
        setConfirmPassword('');
    };

    const handleConfirm = () => {
        const numberPattern = /\d/; // At least one digit
        const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/; // At least one special character
        const uppercasePattern = /[A-Z]/; // At least one uppercase letter
        const lowercasePattern = /[a-z]/; // At least one lowercase letter

        if (password !== confirmPassword || password === '') {
            alert("Passwords do not match or are empty. Please try again.");
            return;
        }

        if (!numberPattern.test(password)) {
            alert("Password must contain at least one number.");
            return;
        }

        if (!specialCharPattern.test(password)) {
            alert("Password must contain at least one special character.");
            return;
        }

        if (!uppercasePattern.test(password)) {
            alert("Password must contain at least one uppercase letter.");
            return;
        }

        if (!lowercasePattern.test(password)) {
            alert("Password must contain at least one lowercase letter.");
            return;
        }

        // If all checks pass
        onConfirm(password);
        setPassword('');
        setConfirmPassword('');
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="password-change-dialog-title" sx={{ '& .MuiDialog-paper': { width: '20vw' } }}>

            <DialogTitle id="password-change-dialog-title">{'Password reset'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="password"
                    label="New Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="confirm-password"
                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};


// Context Provider Component
export const DialogProvider = ({ children }) => {
    // State for the standard dialog
    const [dialogProps, setDialogProps] = useState({
        open: false,
        alertText: '',
        onConfirm: () => {},
        onCancel: () => {},
    });

    // State for the input dialog
    const [inputDialogProps, setInputDialogProps] = useState({
        open: false,
        prompt: '',
        onConfirm: () => {},
        onCancel: () => {},
    });

    // State for the custom password change dialog
    const [passwordChangeDialogProps, setPasswordChangeDialogProps] = useState({
        open: false,
        onConfirm: () => {},
        onCancel: () => {},
    });

    // Function to request a confirmation dialog
    const requestConfirm = useCallback((title, alertText) => {
        return new Promise((resolve, reject) => {
            setDialogProps({
                title,
                open: true,
                alertText,
                onConfirm: () => {
                    resolve(true); // Resolve the promise when confirmed
                    setDialogProps((prev) => ({ ...prev, open: false }));
                },
                onCancel: () => {
                    resolve(false); // Resolve the promise as false
                    setDialogProps((prev) => ({ ...prev, open: false }));
                },
            });
        });
    }, []);

    // Function to request an input dialog
    const requestInput = useCallback((title, prompt) => {
        return new Promise((resolve, reject) => {
            setInputDialogProps({
                title,
                open: true,
                prompt,
                onConfirm: (inputValue) => {
                    resolve(inputValue); // Resolve the promise with the input value
                    setInputDialogProps((prev) => ({ ...prev, open: false }));
                },
                onCancel: () => {
                    resolve(null); // Resolve the promise with null on cancel
                    setInputDialogProps((prev) => ({ ...prev, open: false }));
                },
            });
        });
    }, []);

    // Function to request a password change dialog
    const requestPasswordChange = useCallback(() => {
        return new Promise((resolve, reject) => {
            setPasswordChangeDialogProps({
                open: true,
                onConfirm: (password) => {
                    resolve(password); // Resolve the promise with the new password
                    setPasswordChangeDialogProps((prev) => ({ ...prev, open: false }));
                },
                onCancel: () => {
                    resolve(null); // Resolve the promise with null on cancel
                    setPasswordChangeDialogProps((prev) => ({ ...prev, open: false }));
                },
            });
        });
    }, []);

    return (
        <DialogContext.Provider value={{ requestConfirm, requestInput, requestPasswordChange }}>
            {children}
            <CustomDialog {...dialogProps} />
            <CustomInputDialog {...inputDialogProps} />
            <CustomPasswordChangeDialog {...passwordChangeDialogProps} />
        </DialogContext.Provider>
    );
};