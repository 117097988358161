import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'

// From the API
import { useSelector } from "react-redux";
import { useUpdateProjectMutation } from "services/api";

const initialSteps = [
    { label: 'Step 0', completed: false },
    { label: 'Step 1', completed: false },
    { label: 'Step 2', completed: false },
    { label: 'Step 3', completed: false },
];

const pathToStep = {'/intro': 0, '/uploadImages': 1, '/metadata': 2, '/annotate': 3};
const stepToPath = {0: '/intro', 1: '/uploadImages', 2: '/metadata', 3: '/annotate'};

// Custom Hook to fetch the projects
function useUpdateProjectFile(projects, activeProject, numberOfImages, numberOfMetadata) {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [updateProject] = useUpdateProjectMutation();
    const navigate = useNavigate();
    const location = useLocation();

    // States
    const [steps, setSteps] = useState(initialSteps);
    const [activeStep, setActiveStep] = useState(pathToStep[location.pathname] ? pathToStep[location.pathname] : 0);
    const [updating, setUpdating] = useState(false);

    // Effect to update the completed steps of the project from project
    useEffect(() => {
        if(projects.length > 0) {
            const projectObject = projects[activeProject];
            const projectName = Object.keys(projectObject)[0];
            const projectDetails = projectObject[projectName];
            setSteps(projectDetails.steps);
        }
    }, [activeProject, projects]);

    // Effect to navigate to the page
    useEffect(() => {
        if(user && token && projects.length > 0){
            navigate(stepToPath[activeStep]);
        }
    }, [activeStep, navigate, projects, token, user]);

    // Function to update the completed steps
    const updateCompletedSteps = useCallback (async (stepNumber) => {
        setUpdating(true);
        try {
            // Get the project name
            const projectObject = projects[activeProject];
            const projectName = Object.keys(projectObject)[0];
            const projectDetails = { ...projectObject[projectName] };

            // Update the step that was completed
            const updatedSteps = projectDetails.steps.map((step, index) => {
                if (index === stepNumber) {
                    return { ...step, completed: true };
                }
                return step;
            });

            // Update the projectDetails object with the updated steps array
            const updatedProjectDetails = { ...projectDetails, steps: updatedSteps };

            // Fetch the list of projects availables for this user
            const result = await updateProject({ token: token, requestBody: { email: user, projectDetails:updatedProjectDetails, projectName:projectName } }).unwrap();
            if (result.success) {
                // Update the steps locally
                setSteps(updatedSteps);
            } else {
                console.error('Error updating projects details:', result.error);
            }
        } catch (error) {
            console.error('Error updating projects details:', error);
        }
        setUpdating(false);
    }, [projects, activeProject, token, user, updateProject]);

    // Effects to check if the conditions for the completion of the steps are met
    useEffect(() => {
        if (projects.length > 0) {
            // Get the project name
            const projectObject = projects[activeProject];
            const projectName = Object.keys(projectObject)[0];
            const projectDetails = { ...projectObject[projectName] };

            // Get the total images required
            const imagesRequired = projectDetails.imagesRequired;

            // Check if the images required are met
            if (numberOfImages >= imagesRequired) {
                if (!steps[1].completed) {
                    updateCompletedSteps(1);
                }
            }

            // Check if the metadata required are met
            if (numberOfMetadata >= imagesRequired) {
                if (!steps[2].completed) {
                    updateCompletedSteps(2);
                }
            }
        }
    }, [projects, activeProject, numberOfImages, numberOfMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

    return { updateCompletedSteps, steps, activeStep, setActiveStep, updating };
}

export default useUpdateProjectFile;