import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "./dynamicBaseQuery";
import { selectApiUrl } from "utils/State";

export const api = createApi({
    baseQuery: dynamicBaseQuery({ baseUrlSelector: selectApiUrl }),
    reducerPath: "adminApi",
    tagTypes: ["User"],
    endpoints: (build) => ({

        // Login user
        loginUser: build.mutation({
            query: (loginDetails) => ({
                url: "auth/login",
                method: "POST",
                body: loginDetails,
            }),
            invalidatesTags: ["User"],
        }),

        // Update user password
        updateUserPassword: build.mutation({
            query: ({loginDetails}) => ({
                url: "auth/updateUserPassword",
                method: "POST",
                body: loginDetails,
            }),
            invalidatesTags: ["User"],
        }),

        // Request password change
        resetPassword: build.mutation({
            query: ({loginDetails}) => ({
                url: "auth/resetPassword",
                method: "POST",
                body: loginDetails,
            }),
            invalidatesTags: ["User"],
        }),

        // Image upload
        uploadImage: build.mutation({
            query: ({ token, file, additionalInfo }) => {
                let formData = new FormData();
                formData.append("file", file);
                Object.keys(additionalInfo).forEach(key => {
                    formData.append(key, additionalInfo[key]);
                });

                return {
                    url: "image/uploadImage",
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                };
            },
            invalidatesTags: ["Image"],
        }),

        // Fetch Number of Images
        fetchNumberOfImages: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/fetchNumberOfImages",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),

        // Fetch Image Tokens
        fetchImageTokens: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/fetchImageTokens",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),

        fetchImageMetadata: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/fetchImageMetadata",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),

        fetchImageAnnotations: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/fetchImageAnnotations",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),

        fetchNumberOfMetadata: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/fetchNumberOfMetadata",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),

        fetchNumberOfAnnotations: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/fetchNumberOfAnnotations",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),

        updateImageMetadata: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/updateImageMetadata",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),

        updateImageAnnotations: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/updateImageAnnotations",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),

        fetchProjects: build.mutation({
            query: ({token, requestBody}) => ({
                url: "project/fetchProjects",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Project"],
        }),

        updateProject: build.mutation({
            query: ({token, requestBody}) => ({
                url: "project/updateProject",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Project"],
        }),

        fetchImageData: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/fetchImageData",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),

        deleteImage: build.mutation({
            query: ({token, requestBody}) => ({
                url: "image/deleteImage",
                method: "POST",
                headers: {Authorization: `Bearer ${token}`},
                body: requestBody,
            }),
            invalidatesTags: ["Image"],
        }),
    })

});

// Export the hooks
export const {
    useLoginUserMutation,
    useUpdateUserPasswordMutation,
    useResetPasswordMutation,
    useUploadImageMutation,
    useFetchProjectsMutation,
    useUpdateProjectMutation,
    useFetchNumberOfImagesMutation,
    useFetchNumberOfMetadataMutation,
    useFetchNumberOfAnnotationsMutation,
    useFetchImageTokensMutation,
    useFetchImageMetadataMutation,
    useUpdateImageMetadataMutation,
    useFetchImageAnnotationsMutation,
    useUpdateImageAnnotationsMutation,
    useFetchImageDataMutation,
    useDeleteImageMutation} = api;