import React, { useState } from 'react';
import { Box } from '@mui/material';

// Contexts
import { useSidebar } from 'utils/SidebarProvider'
import { useDialog } from 'utils/DialogProvider';

// Custom Components
import CustomImageNavigation from 'components/common/CustomImageNavigation';
import CustomImageEditor from 'components/common/CustomImageEditor';


const Annotate = () => {
    // States and theme
    const [accessToken, setAccessToken] = useState('');

    // Context
    const { labels, annotations, annotationCompletedList, setAnnotations, updateAnnotations } = useSidebar();
    const { requestConfirm } = useDialog();

    const handleSave = async () => {
        // Check that there is at least one annotation per label type verifying annotations.label and label.label
        for (let i = 0; i < labels.length; i++) {
            const label = labels[i];
            if (!annotations.find(annotation => annotation.label === label.label)) {
                const result = await requestConfirm('Warning', `There is no annotations for the label ${label.label}. Do you want to continue?`);
                if (!result) return;
            }
        }
        await updateAnnotations(annotations);

    };

    // Render the component
    return(
        <Box display="flex" flexDirection="column" justifyContent="flex-start" flexGrow={1}>

            {/* Basic information */}
            < CustomImageNavigation setAccessToken={setAccessToken} completedList={annotationCompletedList} />

            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1} gap="1rem" padding="1rem">
                {accessToken !== '' ? (
                    <>
                        <CustomImageEditor
                            accessToken={accessToken}
                            annotations={annotations? annotations : []}
                            setAnnotations={setAnnotations}
                            labels={labels? labels : []}
                            handleSave={handleSave}
                        />

                    </>
                    ) : (
                        <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center' width='100%' height='100%' padding='1rem'/>
                )}
            </Box>

        </Box>
    );
};

export default Annotate;