import { useState } from "react";

// From the API
import { useSelector } from "react-redux";
import { useUploadImageMutation } from 'services/api';

// Custom Hook to fetch the projects
function useUpdateImage(projects, activeProject) {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [ uploadImage ] = useUploadImageMutation();
    const [updating, setUpdating] = useState(false);

    // Function to update the metadata
    const uploadImageFile = async (file) => {
        setUpdating(true);
        try {
            // Get the project name
            const projectObject = projects[activeProject];
            const projectName = Object.keys(projectObject)[0];

            // Send the file to the server
            await uploadImage({ token, file: file.file, additionalInfo: {email: user, projectName: projectName} }).unwrap();
            setUpdating(false);
            return true;

        // Step 5. Handle errors
        } catch (error) {
            setUpdating(false);
            return false;
        }
    }

    return { uploadImageFile, updating };
}

export default useUpdateImage;