import React from 'react';
import { Box, Drawer, Typography, useTheme } from '@mui/material'

// Contexts
import { useSidebar } from 'utils/SidebarProvider'

// Custom Components
import ProgressControl from './ProgressControl';

// Primary Sidebar Component
const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, sideBarWidth }) => {
    // States
    const theme = useTheme();

    // States
    const { projects } = useSidebar();

    // If the sidebar is closed, return a empty box
    if (!isSidebarOpen) {
        return <Box />;
    }

    // If the sidebar is open, return its content
    return (
        <Box component="nav" backgroundColor='green'>
            <Drawer
                    open={isSidebarOpen}
                    onClose={() => setIsSidebarOpen(false)}
                    variant="persistent"
                    anchor="left"
                    style={{ height: '100%' }}
                    sx={{
                        width: sideBarWidth,
                        "& .MuiDrawer-paper": {
                            color: theme.palette.secondary[0],
                            fontWeight: "bold",
                            backgroundColor: '#1c3340',
                            boxSixing: "border-box",
                            borderWidth: "2px",
                            width: sideBarWidth,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderRight: `1px solid ${theme.palette.grey[300]}`,
                        },
                    }}
                >

                    {/* 1. Anotia Settings */}
                    <Box width="100%" height="70px" bgcolor={theme.palette.main[500]} p={theme.spacing(3)} display="flex"
                        justifyContent="center" alignItems="center" sx={{borderBottom:`1px solid ${theme.palette.grey[300]}`}}>
                            <Typography variant="h4" component="h4" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.5rem' }}>
                                Anotia Settings
                            </Typography>
                    </Box>

                    {/* PROGRESS CONTROL */}
                    {projects.length > 0 ? (
                        <ProgressControl />
                    ) : (
                        <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center' width='100%' height='100%' padding='1rem' bgcolor='white'/>
                    )}

            </Drawer>
        </Box>
    );
};

export default Sidebar;
