// From the API
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetchNumberOfAnnotationsMutation } from 'services/api';

// Custom Hook to fetch the projects
function useFetchNumberOfAnnotations(projects, activeProject) {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [ fetchNumberOfAnnotations ] = useFetchNumberOfAnnotationsMutation();

    // State
    const [numberOfAnnotations, setNumberOfAnnotations] = useState(0);
    const [annotationCompletedList, setAnnotationCompletedList] = useState([]);
    const [refreshIndex, setRefreshIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    // Function to refresh the number of Annotations
    const refreshNumberOfAnnotations = () => {
        setRefreshIndex((prevRefreshIndex) => prevRefreshIndex + 1);
    };

    useEffect(() => {
        // Function to update the Annotations
        const fetchNumber = async () => {
            setLoading(true);
            try {
                const projectObject = projects[activeProject];
                const projectName = Object.keys(projectObject)[0];

                // Get the number of Annotations files in the directory
                const response = await fetchNumberOfAnnotations({ token: token, requestBody: { email: user, projectName: projectName } }).unwrap();
                if (response.success) {
                    setNumberOfAnnotations(response.number);
                    setAnnotationCompletedList(response.completedList);
                } else {
                    console.log('Error updating Annotations');
                }
            } catch (error) {
                console.log('Error updating Annotations');
            }
            setLoading(false);
        };
        if (projects.length > 0){
            fetchNumber();
        }
    }, [projects, activeProject, refreshIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return { numberOfAnnotations, annotationCompletedList, refreshNumberOfAnnotations, loading };
}

export default useFetchNumberOfAnnotations;