import { useState } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useUpdateImageAnnotationsMutation } from 'services/api';

// Custom Hook to fetch the projects
function useUpdateAnnotations(projects, activeProject, imageList, activeImage, refreshNumberOfAnnotations) {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [ updateImageAnnotations ] = useUpdateImageAnnotationsMutation();
    const [updating, setUpdating] = useState(false);

    // Function to update the Annotations
    const updateAnnotations = async (annotations) => {
        setUpdating(true);
        try {
            const projectObject = projects[activeProject];
            const projectName = Object.keys(projectObject)[0];
            const requestBody = { email: user, projectName:projectName, imageName: imageList[activeImage].fileName,
                                annotations: annotations };

            // Fetch the list of projects availables for this user
            const result = await updateImageAnnotations({ token: token, requestBody: requestBody }).unwrap();
            if (result.success) {
                // Update the number of Annotations files
                console.log('Annotations updated');
                refreshNumberOfAnnotations();
            } else {
                console.log('Error updating Annotations');
            }
        } catch (error) {
            console.log('Error updating Annotations');
        }
        setUpdating(false);
    }

    return { updateAnnotations, updating };
}

export default useUpdateAnnotations;