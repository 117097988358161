import { useState, useEffect } from 'react';

// From the API
import { useSelector } from "react-redux";
import { useFetchImageTokensMutation } from "services/api";

// Custom Hook to fetch the projects
function useFetchTokens(projects, activeProject) {

    // Utils
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);
    const [fetchImageTokens] = useFetchImageTokensMutation();

    // States
    const [imageList, setImageList] = useState([]);
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    // Function to refresh the number of Annotations
    const refreshTokens = () => {
        setRefreshIndex((prevRefreshIndex) => prevRefreshIndex + 1);
    };

    // Use effect to update the values when the active project changes
    useEffect(() => {
        // Function to fetch the projects just once at the beginning
        const fetchTokens = async () => {
            setLoading(true);
            // Ensure the user and the token are available
            if (user && token) {
                try {
                    const projectObject = projects[activeProject];
                    const projectName = Object.keys(projectObject)[0];
                    const projectDetails = projectObject[projectName];

                    // Fetch the list of projects availables for this user
                    const result = await fetchImageTokens({ token: token, requestBody: { email: user, projectName:projectName } }).unwrap();
                    if (result.success) {
                        setImageList(result.data);
                        setLabels(projectDetails.labels)
                        console.log('Tokens fetched:', result.data);
                    } else {
                        setImageList([]);
                        setLabels([]);
                        console.error('Error fetching projects:', result.error);
                    }
                } catch (error) {
                    setImageList([]);
                    setLabels([]);
                    console.error('Error fetching projects:', error);
                }
            }
            setLoading(false);
        };

        // If projects is not empty
        if (projects.length > 0) {
            fetchTokens();
        }
    }, [activeProject, projects, refreshIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return { imageList, labels, refreshTokens, loading};
}

export default useFetchTokens;