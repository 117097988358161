// React and MUI imports
import React, { useRef, useState, useEffect } from 'react';
import { Box, Paper, useTheme, } from '@mui/material';

// Konva
import Konva from 'konva';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import RemoveIcon from '@mui/icons-material/RemoveCircleRounded';

import { useSelector } from "react-redux";
import { useFetchImageDataMutation } from 'services/api';

// Image Layer with Memo to avoid re-rendering
const ImageLayer = React.memo(({ image, imageRef, brightness, contrast }) => {
    return (
        <Layer>
            <KonvaImage
                ref={imageRef}
                image={image}
                filters={[Konva.Filters.Brighten, Konva.Filters.Contrast]}
                brightness={brightness}
                contrast={contrast}
            />
        </Layer>
    );
});

// Image Editor component
const CustomImageViewer = ( { accessToken } ) => {
    const theme = useTheme();
    const token = useSelector((state) => state.persistedReducer.token);
    const [image, setImage] = useState(null);
    const [fetchImageData] = useFetchImageDataMutation();

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const result = await fetchImageData({ token: token, requestBody: { accessToken: accessToken } }).unwrap();
                if (result.data) {
                    const img = new Image();
                    img.onload = () => {
                        setImage(img);
                    };
                    img.onerror = (error) => {
                        console.error('Error loading image:', error);
                    };
                    img.src = result.data;
                }
            } catch (error) {
                console.error('Error fetching image data', error);
            }
        };

        if (accessToken) {
            fetchImage();
        }
    }, [accessToken, fetchImageData, token]);

    // Refs for the stage, paper, and image
    const stageRef = useRef(null);
    const paperRef = useRef(null);
    const imageRef = useRef(null);

    // Sate size and original dimensions
    const [stageSize, setStageSize] = useState({ width: 500, height: 100 });

    // Editor initial state
    const initialState = {
        scale: 1,
        position: { x: 0, y: 0 },
        drawing: false,
        lines: [],
        shapes: [],
        currentMode: 'PANNING',
        brightness: 0,
        contrast: 0,
        strokeSize: 5,
        selectedLabel: { 'text': '', 'color': '#000000' },
        tooltipOpen: false,
        tooltipContent : '',
        tooltipPosition: { x: 0, y: 0 },
        highlightedShapeId: null,
        toolbarDisabled: true,
    };
    const [state, setState] = useState(initialState);

    // Function to update the state
    const updateState = (newValues) => {
        setState((prevState) => ({
            ...prevState,
            ...newValues,
        }));
    };

    // Place the image in the center of the canvas at the start
    useEffect(() => {
        if (image && stageRef.current) {
            // Determine scale to fit the image in the canvas, maintaining aspect ratio
            const scaleX = stageSize.width / image.width;
            const scaleY = stageSize.height / image.height;
            const scaleToFit = Math.min(scaleX, scaleY);

            // Calculate centered position based on scaled image size
            const centeredX = (stageSize.width - (image.width * scaleToFit)) / 2;
            const centeredY = (stageSize.height - (image.height * scaleToFit)) / 2;

            updateState({
                scale: scaleToFit,
                position: { x: centeredX, y: centeredY },
                originalDimensions: { width: image.width, height: image.height },
            });

            // Apply transformations to stage for immediate visual update
            stageRef.current.scale({ x: scaleToFit, y: scaleToFit });
            stageRef.current.position({ x: centeredX, y: centeredY });
            stageRef.current.batchDraw();
        }
    }, [image, stageRef]); // eslint-disable-line react-hooks/exhaustive-deps

    // Cache the image when it's loaded (for brightness and contrast filters)
    useEffect(() => {
        if (image) {
            imageRef.current.cache();
            imageRef.current.getLayer().batchDraw();

            // Enable the toolbar
            updateState({ toolbarDisabled: false });
        }
    }, [image]);

    // Update the stage size when the paper ref changes
    useEffect(() => {
        const updateStageSize = () => {
            if (paperRef.current) {
                setStageSize({
                    width: paperRef.current.offsetWidth,
                    height: paperRef.current.offsetHeight,
                });
            }
        };

        // Update stage size initially
        updateStageSize();
        window.addEventListener('resize', updateStageSize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', updateStageSize);
    }, []);

    // Zoom in and out with the mouse wheel
    const handleWheel = (e) => {
        if (state.currentMode !== 'PANNING') return; // Only zoom if panning is enabled

        e.evt.preventDefault();
        const scaleBy = e.evt.deltaY > 0 ? 0.9 : 1.1; // Faster zoom on wheel scroll

        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        const newScale = oldScale * scaleBy;

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };

        // Set the new scale and position
        updateState({ scale: newScale });
        updateState({ position: newPos });
    };

    // Mouse down handlers
    const handleMouseDown = (e) => {
        if (state.currentMode === 'DRAWING') {
            updateState({ drawing: true });
            // Get the current stage
            const stage = stageRef.current.getStage();
            // Get the mouse position relative to the stage
            const pos = stage.getPointerPosition();
            // Adjust the position based on the stage's scale and position
            const adjustedPos = {
                x: (pos.x - stage.x()) / stage.scaleX(),
                y: (pos.y - stage.y()) / stage.scaleX(), // Assuming uniform scaling for simplicity
            };
            updateState({ lines: [...state.lines, { points: [adjustedPos.x, adjustedPos.y] }] });
        }
    };

    // Mouse move handlers
    const handleMouseMove = (e) => {
        if (!state.drawing || state.currentMode !== 'DRAWING') return;

        requestAnimationFrame(() => {
            const stage = stageRef.current.getStage();
            const point = stage.getPointerPosition();
            // Adjust the point based on the stage's transformation
            const adjustedPoint = {
                x: (point.x - stage.x()) / stage.scaleX(),
                y: (point.y - stage.y()) / stage.scaleX(),
            };

            let lastLine = state.lines[state.lines.length - 1];
            // Update the last line with the new, adjusted point
            lastLine.points = lastLine.points.concat([adjustedPoint.x, adjustedPoint.y]);
            state.lines.splice(state.lines.length - 1, 1, lastLine);
            updateState({ lines: state.lines.concat() });
        });
    };

    // Mouse up handler
    const handleMouseUp = () => {
        updateState({ drawing: false });
    };

    const zoomIn = () => {
        const newScale = state.scale * 1.1; // Increase scale by 10%
        updateState({
            scale: newScale,
            position: {
                x: state.position.x - (stageSize.width * 0.05), // Adjust position to zoom towards center
                y: state.position.y - (stageSize.height * 0.05),
            }
        });
    };

    const zoomOut = () => {
        if (state.scale > 0.1) { // Prevent zooming out too much
            const newScale = state.scale * 0.9; // Decrease scale by 10%
            updateState({
                scale: newScale,
                position: {
                    x: state.position.x + (stageSize.width * 0.05), // Adjust position to zoom towards center
                    y: state.position.y + (stageSize.height * 0.05),
                }
            });
        }
    };


    let animationFrameId = null;
    const handleTouchMove = (e) => {
        e.evt.preventDefault();
        const stage = e.target.getStage();
        if (e.evt.touches.length === 2) {
            const touch1 = { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY };
            const touch2 = { x: e.evt.touches[1].clientX, y: e.evt.touches[1].clientY };

            const distance = Math.sqrt(Math.pow(touch2.x - touch1.x, 2) + Math.pow(touch2.y - touch1.y, 2));

            if (state.lastDist) {
                const scaleBy = distance / state.lastDist;
                const newScale = state.scale * scaleBy;

                const centerX = (touch1.x + touch2.x) / 2 - stage.container().getBoundingClientRect().left;
                const centerY = (touch1.y + touch2.y) / 2 - stage.container().getBoundingClientRect().top;

                if (animationFrameId) {
                    cancelAnimationFrame(animationFrameId);
                }

                animationFrameId = requestAnimationFrame(() => {
                    const newPos = {
                        x: stage.x() - (centerX - stage.x()) * (scaleBy - 1),
                        y: stage.y() - (centerY - stage.y()) * (scaleBy - 1),
                    };

                    updateState({ scale: newScale, position: newPos, lastDist: distance });
                });
            } else {
                updateState({ lastDist: distance });
            }
        }
    };

    const handleTouchStart = (e) => {
        if (e.evt.touches.length === 2) {
            const touch1 = { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY };
            const touch2 = { x: e.evt.touches[1].clientX, y: e.evt.touches[1].clientY };
            const distance = Math.sqrt(Math.pow(touch2.x - touch1.x, 2) + Math.pow(touch2.y - touch1.y, 2));
            updateState({ lastDist: distance });
        }
    };

    const handleTouchEnd = (e) => {
        if (e.evt.touches.length < 2) {
            updateState({ lastDist: null });
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        }
    };

    // Return the component
    return (
        <Paper
            elevation={3}
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection:'column',
                flexGrow: 1,
                overflow: 'hidden',
                backgroundColor:theme.palette.secondary[100],
                border: "1px solid #ccc",
                borderRadius: '10px',
                position: 'relative',
            }}
        >

            <Box ref={paperRef} flexGrow={1} sx={{ position: 'relative' }}>

                <IconButton onClick={zoomIn} color="primary" sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1000}}>
                    <AddIcon />
                </IconButton>

                <IconButton onClick={zoomOut} color="primary" sx={{ position: 'absolute', top: 60, right: 10, zIndex: 1000 }}>
                    <RemoveIcon />
                </IconButton>


                <Stage
                    ref={stageRef}
                    width={stageSize.width}
                    height={stageSize.height}
                    scaleX={state.scale}
                    scaleY={state.scale}
                    x={state.position.x}
                    y={state.position.y}
                    draggable={state.currentMode === 'PANNING'}
                    onWheel={handleWheel}
                    onMouseDown={handleMouseDown}
                    onMousemove={handleMouseMove}
                    onMouseup={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    onDragEnd={() => {updateState({ position: { x: stageRef.current.x(), y: stageRef.current.y() } });}}
                >
                    <ImageLayer image={image} imageRef={imageRef} stageSize={stageSize} brightness={state.brightness} contrast={state.contrast} />

                </Stage>

            </Box>

        </Paper>
    );
}

export default CustomImageViewer;