import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Pagination, PaginationItem, TextField, Button, useTheme } from '@mui/material';
import { useSidebar } from 'utils/SidebarProvider';
import { useDialog } from 'utils/DialogProvider';
import { useSelector } from "react-redux";
import { useDeleteImageMutation } from 'services/api';

const CustomImageNavigation = ({ setAccessToken, completedList }) => {
    const theme = useTheme();
    const { projects, activeProject, imageList, activeImage, setActiveImage, refreshProject, setIsDeleting } = useSidebar();
    const [fileName, setFileName] = useState('');
    const [completedFlags, setCompletedFlags] = useState([]);
    const [inputPage, setInputPage] = useState(activeImage + 1);
    const { requestConfirm } = useDialog();
    const [deleteImageMutation] = useDeleteImageMutation();
    const user = useSelector((state) => state.persistedReducer.user);
    const token = useSelector((state) => state.persistedReducer.token);

    // Set the file name every time the active image changes
    useEffect(() => {
        if (imageList.length > 0) {
            setFileName(imageList[activeImage].fileName);
            setAccessToken(imageList[activeImage].url);
        }
    }, [imageList, activeImage, setAccessToken]);

    useEffect(() => {
        if (imageList.length > 0) {
            // Remove the extension from the file names
            const imageListWithoutExtension = imageList.map(image => image.fileName.split('.')[0]);
            const completedListWithoutExtension = completedList.map(image => image.split('.')[0]);

            // Set the completed flags following the order of the image list
            const flags = imageListWithoutExtension.map(image => completedListWithoutExtension.includes(image));
            setCompletedFlags(flags);
        }
    }, [completedList, imageList]);

    // Handler for change in pagination
    const handlePageChange = (event, value) => {
        setActiveImage(value - 1);
        setFileName(imageList[value - 1].fileName);
        setAccessToken(imageList[value - 1].url);
    };

    const handleInputPageChange = (event) => {
        setInputPage(event.target.value);
    };

    const jumpToPage = () => {
        const pageNumber = Number(inputPage) - 1;
        if (pageNumber >= 0 && pageNumber < imageList.length) {
            handlePageChange(null, pageNumber + 1);
        }
    };

    const deleteImage = async () => {
        // Check if there is an image to delete
        if (imageList.length === 0) {
            return;
        }

        // Request confirmation
        const result = await requestConfirm('Delete Image', 'Are you sure you want to delete the image?');
        if (result) {
            try{
                setIsDeleting(true);
                const projectObject = projects[activeProject];
                const projectName = Object.keys(projectObject)[0];

                const response = await deleteImageMutation({ token: token, requestBody: { email: user, projectName:projectName, fileName:imageList[activeImage].fileName } }).unwrap();
                console.log('Response:', response);
                if(response.success){
                    setTimeout(() => {
                        refreshProject();
                        setActiveImage(0);
                    }, 2000);
                    refreshProject();
                    setActiveImage(0);
                }

            }
            catch(error){
                console.error('Error deleting image:', error);
            }
            finally{
                setIsDeleting(false);
            }
        }
    };

    return (
        <Box width="100%" height="100px" bgcolor={'#E6ECF7'} p={theme.spacing(3)} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" height="70px" maxWidth="100%" gap='1rem'>

                <Box display="flex" alignItems="center" flexDirection='column'>
                    <Typography variant="h5" component="h5" sx={{ color: '#003082', fontSize: '1.5rem' }}>Navigation</Typography>
                    <Button onClick={deleteImage}>Delete Image</Button>
                </Box>

                <Divider orientation="vertical" variant="middle" flexItem />

                <Box display="flex" alignItems="left" gap='0.05rem' justifyContent='center' flexDirection='column'>
                    <Typography variant="h6" component="h6" sx={{ color: '#003082', fontSize: '1.0rem' }}>
                        {imageList.length > 0 ? <><strong>Name</strong>: {fileName}</> : <><strong>Name</strong>: </>}
                    </Typography>
                    <Typography variant="h6" component="h6" sx={{ color: '#003082', fontSize: '1.0rem' }}>
                        {imageList.length > 0 ? <><strong>Number</strong>: {activeImage + 1} of {imageList.length}</> : <><strong>Number</strong>: </>}
                    </Typography>
                </Box>

            </Box>

            <Box display='flex' flexDirection='row' height='100%' alignItems='center'>
                <Pagination
                    variant="outlined"
                    siblingCount={1}
                    count={imageList.length}
                    page={activeImage + 1}
                    onChange={handlePageChange}
                    color="primary"
                    renderItem={(item) => (
                        <PaginationItem
                            {...item}
                            sx={{...(item.type === 'page' && completedFlags[item.page - 1] && {backgroundColor: 'lightgreen'})}}
                        />
                    )}
                />

            </Box>

            <Box display="flex" flexDirection='row' alignItems="center" ml='1rem'>
                <TextField
                    type="number"
                    variant="outlined"
                    value={inputPage}
                    onChange={handleInputPageChange}
                    InputProps={{ inputProps: { min: 1, max: imageList.length } }}
                    sx={{ mr: 2 }}
                />
                <Button variant="contained" color="primary" onClick={jumpToPage}>
                    Go
                </Button>
            </Box>
        </Box>
    );
};

export default CustomImageNavigation;
