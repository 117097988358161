import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, TextareaAutosize, Paper, Stepper, Step, StepLabel, StepIcon, Button, Typography, useTheme } from '@mui/material';
import ReactPlayer from 'react-player';

// Contexts
import { useSidebar } from 'utils/SidebarProvider'

// Custom Components
import CustomButton from 'components/common/CustomButton';

// Tutorial video
const video = 'https://vimeo.com/949168917';

const Step3 = ({ projectName, description }) => {
    // States
    const theme = useTheme();

    return(
        <Box display="flex" flexDirection='column' justifyContent="flex-start" flexGrow={1} width='100%' padding='2rem'>
            <Typography variant="h3" color={theme.palette.main[400]} sx={{ pb: "1rem" }}>
                <strong>{projectName}</strong>:
            </Typography>

            <Box
                sx={{
                    maxHeight: '245px',
                    overflow: 'auto',
                    width: '100%',
                    p: theme.spacing(1)
                }}
            >
                <TextareaAutosize
                    minRows={3}
                    style={{
                        width: '100%',
                        backgroundColor: 'transparent',
                        color: theme.palette.text.primary,
                        border: 'none',
                        fontSize: '1rem',
                        fontFamily: 'inherit',
                        resize: 'none',
                        textAlign: 'justify',
                        textJustify: 'inter-word'

                    }}
                    value={description}
                    disabled={true}
                />
            </Box>
        </Box>
    );
}

const Step2 = ({ imagesRequested, labels }) => {
    // States
    const theme = useTheme();

    return(
        <Box display="flex" flexDirection='column' justifyContent="flex-start" flexGrow={1} width='100%' padding='2rem'>
            <Typography variant="h4" color={theme.palette.main[400]} sx={{ pb: "1rem" }}>
                <strong>{imagesRequested}</strong> images have been requested for this project to annotate:
            </Typography>

            <Paper elevation={3} sx={{display:"flex", flexDirection:"row",flexWrap:"wrap", justifyContent:"center", alignItems:"center", gap:2, paddingTop:'1rem',
                                      paddingBottom:'1rem', overflowY: 'auto', maxHeight: '245px', width: '100%', height:'100%'}}>
                {labels.map((label, index) => (
                    <Card key={index} sx={{ width: 200, backgroundColor: label.color }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                {label.label}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                Required: {label.required}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Paper>

        </Box>
    );
}

const Step1 = () => {
    // States
    const theme = useTheme();

    return(
        <Box display="flex" flexDirection='column' justifyContent="flex-start" flexGrow={1} width='100%' padding='2rem'>

            <ReactPlayer
                url={video}
                controls={true}
                playing={false}
                width='100%'
                height='100%'
                style={{
                    maxHeight: '290px',
                    outline: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: theme.shape.borderRadius,
                }}
            />
        </Box>
    );
}

const Step4 = ({ handleComplete }) => {
    // States
    const theme = useTheme();

    return(
        <Box display="flex" flexDirection='column' justifyContent="center" alignItems='center' flexGrow={1} width='100%' padding='2rem'>

            <Typography variant="h2" color={theme.palette.main[400]} sx={{ pb: "0.5rem" }}>
                Thanks for completing the tutorial!
            </Typography>

            <Typography variant="h4" color={theme.palette.main[400]} sx={{ pb: "3rem" }}>
                You can now start the project.
            </Typography>

            <CustomButton onClick={handleComplete} buttonText="Complete" />
        </Box>
    );
}

const Intro = () => {
    // States
    const theme = useTheme();
    const steps = ['Tutorial', 'Goals', 'Description', 'Summary'];
    const { projects, activeProject, updateCompletedSteps } = useSidebar();

    // States for the project information
    const [projectName, setProjectName] = useState('');
    const [imagesRequested, setImagesRequested] = useState(200);
    const [labels, setLabels] = useState([]);
    const [description, setDescription] = useState('');

    // Use effect to update the values when the active project changes
    useEffect(() => {
        // If projects is not empty
        if (projects.length > 0) {
            const projectObject = projects[activeProject];
            const projectName = Object.keys(projectObject)[0];
            const projectDetails = projectObject[projectName];

            // Update the states
            setProjectName(projectName);
            setImagesRequested(projectDetails.imagesRequired);
            setLabels(projectDetails.labels);
            setDescription(projectDetails.description);
        }
    }, [activeProject, projects]);

    // Stepper control
    const [activeStep, setActiveStep] = useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleComplete = () => {
        updateCompletedSteps(0);
    };

    // Render the component
    return(
        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
            <Paper
                    elevation={20}
                    style={{ width: '80vw', maxWidth: '50rem', height: '35rem', maxHeight: '60rem', borderRadius: '1rem', display: 'flex',
                             flexDirection: 'column', alignItems: 'center', backgroundColor:'white',
                             boxShadow: `8px 8px 30px ${theme.palette.black[900]}`
                    }}
                >

                {/* Title */}
                <Typography variant="h3" sx={{ color: theme.palette.main[500], fontWeight: 'bold', padding:'1rem', mb:'1rem'}}>
                    Welcome to Anotia
                </Typography>

                {/* Stepper */}
                <Stepper activeStep={activeStep} alternativeLabel sx={{width:'100%'}}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={props => (
                                <StepIcon {...props} sx={{ fontSize: '30px',
                                    color: 'primary.main',
                                    '.MuiStepIcon-text': {
                                        fill: 'white',
                                        fontSize: '1rem'
                                    }
                                }} />
                            )}>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {/* Content */}
                {activeStep === 0 && (
                    < Step1 />
                )}

                {activeStep === 1 && (
                    < Step2 imagesRequested={imagesRequested} labels={labels} />
                )}

                {activeStep === 2 && (
                    < Step3 projectName={projectName} description={description} />
                )}

                {activeStep === 3 && (
                    < Step4 handleComplete={handleComplete}/>
                )}

                {/* Buttons */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems:'right', width:'100%', p: 2 }}>
                    <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                        Back
                    </Button>
                    <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>

            </Paper>
        </Box>
    );
};

export default Intro;


