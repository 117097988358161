import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "utils/State";
import { Formik } from "formik";
import * as yup from 'yup';

// Custom Components
import CustomButton from 'components/common/CustomButton';
import CustomTextField from 'components/common/CustomTextField';

// Dialog Context
import { useDialog } from 'utils/DialogProvider';

// API
import { useLoginUserMutation, useUpdateUserPasswordMutation, useResetPasswordMutation } from 'services/api';

// Forms
const LoginForm = () => {
    // Utils
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // API and dialog
    const { requestConfirm, requestInput, requestPasswordChange } = useDialog();
    const [loginUser] = useLoginUserMutation();
    const [updateUserPassword] = useUpdateUserPasswordMutation();
    const [resetPassword] = useResetPasswordMutation();

    // Initial values
    const initialValues = { email: '', password: ''};

    // Schema for validation
    const schema = yup.object().shape({
        email: yup.string().email('Invalid email address').required('Email is required'),
        password: yup.string().required('Password is required'),
    });

    // On Submit
    const handleFormSubmit = async (values) => {
        try {
            // Step 1. Call the user login API
            const result = await loginUser(values).unwrap();

            // Step 2. Check if successful and if new password is required
            if(result.success && result.message === 'New password required'){
                const response = await requestPasswordChange();

                // If response is not empty, set the new password
                if(response) {
                    const newPasswordResponse = await updateUserPassword({loginDetails: { session: result.session, password: response, email: values.email }}).unwrap();
                    if(newPasswordResponse.success) {
                        dispatch(setLogin({user: newPasswordResponse.user, token: newPasswordResponse.token}));
                        navigate("/intro");
                    }
                }

            } else if(result.success) {
                dispatch(setLogin({user: result.user, token: result.token}));
                navigate("/intro");

            } else {
                requestConfirm('Login Error', 'Invalid email or password');
            }

        } catch (error) {
            if(error.status === 401) {
                requestConfirm('Login Error', 'Invalid email or password');
            } else {
                requestConfirm('Login Error', 'Contact support for assistance');
            }
        }
    }

    // Forgot Password
    const handleForgotPassword = async () => {
        // Request confirmation
        const response = await requestInput('Pasword Reset', 'Are you sure you want to reset your password?')
        if(response) {
            // Check if response is well formated email
            if(response.includes('@') && response.includes('.')) {
                try {
                    // Call the reset password API
                    const result = await resetPassword({loginDetails: {email: response}}).unwrap();

                    // Check if successful
                    if(result.success) {
                        requestConfirm('Password Reset', 'Check your email for further instructions');
                    } else {
                        requestConfirm('Password Reset', 'Contact support for assistance');
                    }

                } catch (error) {
                    requestConfirm('Password Reset', 'Contact support for assistance');
                }

            } else {
                requestConfirm('Password Reset', 'Invalid email address');
            }
        }
    }

    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">

            {/* 1. Form */}
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleFormSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center' }}>

                            {/* 1.1. Email */}
                            <CustomTextField
                                label="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.email) && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                type={'email'}
                                sx = {{ mb: 4 }}
                            />

                            {/* 1.2. Password */}
                            <CustomTextField
                                label="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.password) && Boolean(errors.password)}
                                helperText={touched.password && errors.password}
                                type={'password'}
                                sx = {{ mb: 6 }}
                            />

                            {/* 1.3. Submit Button */}
                            <CustomButton type="submit" buttonText="Log In" style={{ width: '20vw', maxWidth: '12.5rem', mb: 2, fontSize: '1.25rem' }} />

                            {/* 1.4. Forgot Password */}
                            <Typography
                                variant="h6" color={theme.palette.main[200]} mb={4} onClick={handleForgotPassword}
                                sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                            >
                                Forgot Password?
                            </Typography>

                    </form>
                )}
            </Formik>

        </Box>
    );
};

export default LoginForm;