import { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { themeSettings } from "theme";
import { useDispatch } from "react-redux";
import { setApiUrl } from "utils/State";

// Contexts
import { SidebarProvider } from "utils/SidebarProvider";
import { DialogProvider } from 'utils/DialogProvider';

// Page Components
import Layout from "components/layout/Layout";
import LoginPage from "pages/loginPage";
import UploadImages from "pages/uploadImages";
import Intro from "pages/intro";
import Metadata from "pages/metadata";
import Annotate from "pages/annotate";

// Protected Route Component
const ProtectedRoute = ({ children }) => {
    const isAuth = Boolean(useSelector((state) => state.persistedReducer.token));
    return isAuth ? children : <Navigate to="/" replace />;
};

const LoadConfiguration = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Asynchronously fetch the configuration file
        fetch("config.json")
            .then(response => response.json())
            .then(config => {
                dispatch(setApiUrl({ apiUrl: config.baseUrl }));
                console.log("API Config loaded", config);
            })
            .catch(error => console.error("Failed to load API config", error));
    }, [dispatch]);

    return null;
};

// Main App Component
function App() {
    // Mode from Redux
    const theme = useMemo(() => createTheme(themeSettings()), []);

    return (
        <div className="app">
            <BrowserRouter>
                <LoadConfiguration />
                <SidebarProvider>
                    <DialogProvider>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <Routes>
                                <Route path="/" element={<LoginPage />} />
                                <Route element={<Layout />}>
                                    <Route path="/intro" element={<ProtectedRoute> <Intro /> </ProtectedRoute>} />
                                    <Route path="/uploadImages" element={<ProtectedRoute> <UploadImages /> </ProtectedRoute>} />
                                    <Route path="/metadata" element={<ProtectedRoute> <Metadata /> </ProtectedRoute>} />
                                    <Route path="/annotate" element={<ProtectedRoute> <Annotate /> </ProtectedRoute>} />
                                </Route>
                            </Routes>
                        </ThemeProvider>
                    </DialogProvider>
                </SidebarProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;